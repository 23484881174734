var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "spinner" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }