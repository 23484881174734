import { del, post } from '@/services/api'

//🤮🤮
const state = {
  missingLocations: [], //Location objects that have been removed
  newLocations: [], //Location objects that have been created and are NOT in missing
  changesToApply: [], //Existing locations that have moved between sectors and need to be synced

  tempChanges: [], //Contains text areas with changed data

  locationsSavingStatus: 0, //State for progressbar
  locationsSavingQueue: 0, //How many are still in the queue
  errors: [],
};

const getters = {
  getMissing: state => state.missingLocations,
  isSaving: state => state.locationsSavingQueue > 0,
  getSavingStatus: state => state.locationsSavingStatus/state.locationsSavingQueue*100, //Returns % of the saving
  getLocationErrors: state => state.errors, //Returns % of the saving
};

const actions= {

  saveTempChanges({commit}, {sectorId, newData}) {
    commit('ADD_newTempChanges', {sectorId, newData});
  },

  //This saves all the changes made in tempChanges
  async applyLocationChanges({commit}, data) {
    const newData = data.newData.split('\n');
    const oldData = data.oldData.split('\n');
    const added = newData.filter(location => !oldData.includes(location));
    const missing = oldData.filter(location => !newData.includes(location));

    /* LOCATIONS THAT MOVED */
    let movedLocations = state.missingLocations.filter(loc => added.includes(loc)); //Previously removed and now newly added -> they moved from one sector to another
    movedLocations.filter(loc => !state.changesToApply.map(c => c.location_name).includes(loc)); //We also remove all changes that have been already saved
    movedLocations.forEach((location) => {
      commit('ADD_changeToApply', {location_name: location, new_sector: data.sectorId});
      commit('REMOVE_missingLocations', state.missingLocations.indexOf(location));
    });

    /* NEWLY ADDED */
    const newLocations = added.filter(loc => !movedLocations.includes(loc));
    newLocations.forEach((location) => {
      if(location !== "") commit('ADD_newLocations', {location_name: location, sector: data.sectorId});
    });

    /* MISSING */
    missing.forEach((location) => {
      if(location !== "") {
        //We have to decide if it is actually missing or if the new location got removed -> therefore it's not missing because it never existed
        if (!state.newLocations.map(c => c.location_name).includes(location)) commit('ADD_missingLocations', location); //It's not in new -> it's missing
        else commit('REMOVE_newLocations', state.newLocations.map(c => c.location_name).indexOf(location)); //It is in new -> we remove it
      }
    });
  },

  logFinishStatus({commit, dispatch}) {
    commit('SET_savingStatus', state.locationsSavingStatus+1);
    if (state.locationsSavingStatus == state.locationsSavingQueue) {
        commit('DELETE_changeToApply');
        commit('DELETE_newLocations');
        commit('DELETE_missingLocations');
        commit('DELETE_newTempChanges');
        commit('SET_savingStatus', 0);
        commit('SET_locationsSavingQueue', 0);
        dispatch('fetchLocations');
        if(state.errors.length > 0) dispatch( {text: 'Některé změny se neuložily', type:"error"}, {root:true});
        else dispatch( {text: 'Změny se uložily', timeout: 2000, type:"success"}, {root:true});
    }
  },

  calculateFinishStatus({commit}) {
    commit('ADD_locationsSavingQueue', state.changesToApply.length);
    commit('ADD_locationsSavingQueue', state.newLocations.length);
    commit('ADD_locationsSavingQueue', state.missingLocations.length);
  },


  async prepareChangesToSave({dispatch, rootGetters}, allLocations) {
      await state.tempChanges.reduce( async (p, change) => {
        await p;
        const oldData =  rootGetters.locationsInSector(change.sectorId);
        await dispatch('applyLocationChanges', {...change, oldData: oldData.map(l => l.name).join('\n')})
      }, Promise.resolve());
      dispatch('saveLocationChanges', allLocations);
    },

  async saveLocationChanges({commit, dispatch}, allLocations) {
    dispatch('calculateFinishStatus');

    state.changesToApply.forEach((item) => {
      const locationIndex = allLocations.map(loc => loc.name).indexOf(item.location_name);
      const location = allLocations[locationIndex];
      post(`/location/${location.id}/`, {sector_id: item.new_sector}).then(() => {
          dispatch('logFinishStatus');
      }).catch((e) => {
          commit('ADD_error', {text: e.payload.detail + ": " + item.location_name});
          dispatch('logFinishStatus');
      });
    });

    state.newLocations.forEach((item) => {
      post(`/location/`, {sector_id: item.sector, name: item.location_name}).then(() => {
          dispatch('logFinishStatus');
      }).catch((e) => {
          commit('ADD_error', {text: e.payload.detail + ": " + item.location_name});
          dispatch('logFinishStatus');
      });

    });

    state.missingLocations.forEach((item) => {
      const locationIndex = allLocations.map(loc => loc.name).indexOf(item);
      del(`/location/${allLocations[locationIndex].id}/`).then(() => {
          dispatch('logFinishStatus');
      }).catch((e) => {
          commit('ADD_error', {text: e.payload.detail});
          dispatch('logFinishStatus');
      });
    });

  },
};

const mutations= {
  ADD_changeToApply: (state, data) => state.changesToApply.push(data),
  DELETE_changeToApply: (state) => state.changesToApply = [] ,

  ADD_newLocations: (state, data) => state.newLocations.push(data),
  REMOVE_newLocations: (state, index) => state.newLocations.splice(index, 1),
  DELETE_newLocations: (state) => state.newLocations = [] ,

  ADD_newTempChanges: (state, {sectorId, newData}) => {
    const index = state.tempChanges.map(s => s.sectorId).indexOf(sectorId);
    if(index > -1) state.tempChanges[index] = {sectorId, newData};
    else state.tempChanges.push({sectorId, newData});
  },
  DELETE_newTempChanges: (state) => state.tempChanges = [] ,

  ADD_missingLocations: (state, data) => state.missingLocations.push(data),
  REMOVE_missingLocations: (state, index) => state.missingLocations.splice(index, 1),
  DELETE_missingLocations: (state) => state.missingLocations = [] ,

  SET_savingStatus: (state, value) => state.locationsSavingStatus = value,

  SET_locationsSavingQueue: (state, value) => state.locationsSavingQueue = value,
  ADD_locationsSavingQueue: (state, value) => state.locationsSavingQueue += value,

  ADD_error: (state, error) => state.errors.push(error),
  CLEAR_location_errors: (state) => state.errors = [],
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
