import { get, del } from '@/services/api'

const state = {
  products: [],
  hiddenProducts: [],
  loading: true,
};

const getters= {
  getProducts: state => state.products,
  getHiddenProducts: state => state.hiddenProducts,
  getProductByCode: state => code => state.products.find(p => p.code == code),
  isLoading: state => state.loading,
};

const actions= {
  fetchDeletedProducts({commit}) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      get('/inventory/orphan_products/').then((res) => {
        commit('SET_PRODUCTS', res.data);
        commit('SET_LOADING', false);
        resolve();
      }).catch(() => {
        commit('SET_LOADING', false);
        reject();
      })
    });
  },

  fetchHiddenDeletedProducts({commit}) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      get('/inventory/invisible_products/').then((res) => {
        commit('SET_HIDDEN', res.data);
        commit('SET_LOADING', false);
        resolve();
      }).catch(() => {
        commit('SET_LOADING', false);
        reject();
      })
    });
  },

  deleteSingle({commit, getters}, code) {
    return new Promise((resolve, reject) => {
      const product = getters.getProductByCode(code);
      const products = [{code: product.code, amount: product.amount}];
      const { location_name, sector_name } = product;

      del('/inventory/', {products, location_name, sector_name, source: 'smazany-produkt', shoptet_sync: false }).then((res) => {
        commit('REMOVE_PRODUCTS', code);
        resolve(res);
      }).catch(() => {
        reject();
      })
    });
  },

  deleteProducts({commit, getters}) {
    return new Promise((resolve, reject) => {
      const products = getters.getProducts;
      del('/inventory/orphan_products/', { products }).then((res) => {
        commit('SET_PRODUCTS', []);
        resolve(res);
      }).catch(() => {
        reject();
      })
    });
  }

};

const mutations= {
  SET_PRODUCTS: (state, products) => state.products = products,
  REMOVE_PRODUCTS: (state, code) => state.products = state.products.filter(p => p.code !== code),
  SET_LOADING: (state, bool) => state.loading = bool,
  SET_HIDDEN: (state, products) => state.hiddenProducts = products,
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
