var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        top: "",
        color: _vm.getSnack.type,
        timeout: _vm.getSnack.timeout || -1,
        "data-cy": "snack-alert",
      },
      scopedSlots: _vm._u(
        [
          !_vm.getSnack.timeout
            ? {
                key: "action",
                fn: function (ref) {
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._b(
                        { attrs: { text: "" }, on: { click: _vm.closeSnack } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      [_vm._v(" Zavřít ")]
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.showSnack,
        callback: function ($$v) {
          _vm.showSnack = $$v
        },
        expression: "showSnack",
      },
    },
    [
      _c("v-icon", { staticClass: "pr-3", attrs: { dark: "", large: "" } }, [
        _vm._v(_vm._s(_vm.getIcon)),
      ]),
      _c("span", { staticClass: "white--text" }, [
        _vm._v(" " + _vm._s(_vm.getSnack.text) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }