<template>
  <div :class="{'no-width': !drawer}">
    <v-app-bar-nav-icon v-if="!drawer" class="mobile-menu" @click.stop="manualToggle" large></v-app-bar-nav-icon>
    <v-navigation-drawer
        id="app-drawer"
        app
        floating
        width="265px"
        :mini-variant.sync="isMinified"
        :height="$vuetify.breakpoint.mdAndUp ? '100%' : '100vh'"
        v-model="drawer"
        :disable-resize-watcher="true"
        mobile-breakpoint="820">
      <v-layout column>
        <v-list-item v-if="isMinified" @click="redirect('https://sklad.brani.cz')" class="pa-0 no-radius">
          <v-list-item-content  class="text-center d-block">
            <v-img src="/img/brani-logo-white-center.png" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!isMinified" @click="redirect('https://sklad.brani.cz')" class="no-radius">
          <v-list-item-content class="text-center d-block">
            <v-img src="/img/brani-logo-white-center.png" />
            <v-list-item-action>
              {{ appInfo.name }}
            </v-list-item-action>
            <v-list-item-subtitle>
              {{ appInfo.eshop }}
            </v-list-item-subtitle>
            <span class="warning--text" v-if="enviromentMODE != 'production'">{{enviromentMODE}}</span>
            <p class="font-weight-bold" v-if="enviromentMODE != 'production'">branch: <span class="blue--text">{{branchName}}</span></p>
          </v-list-item-content>
        </v-list-item>
        
        <slot name="top"/>

        <div v-for="(link, i) in menuItems" :key="i">
              <!-- BASIC MENU -->
              <v-list-item
                  v-if="!link.subLinks && !link.hidden"
                  class="v-list-item rounded-r-xl"
                  :to="link.to"
                  :href="link.url"
                  :exact="true"
                  data-cy="menu-item">
                    <v-list-item-action class="ma-0">
                      <v-list-item-icon class="mr-5">
                        <v-icon>{{ link.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item-action>
                    <v-list-item-title> {{link.text}} </v-list-item-title>
                    <v-list-item-action-text>
                      <v-chip small color="red" dark v-if="link.notification">
                        {{ link.notification }}
                      </v-chip>
                      <v-chip small label dark v-if="link.label">
                        {{ link.label }}
                      </v-chip>
                    </v-list-item-action-text>
              </v-list-item>

              <!-- ROLLDOWN -->
              <v-list-group v-else-if="!link.hidden && childrenNotHidden(link.subLinks)" :group="link.to || link.regex">
                <template v-slot:activator>
                  <v-list-item-icon class="mr-1">
                    <v-icon>{{ link.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content data-cy="menu-group">
                    <v-list-item-title>{{ link.text }}</v-list-item-title>
                  </v-list-item-content>
                 </template>
                <div v-for="({text, to, icon, hidden, label, url}, i) in link.subLinks" :key="i">
                  <v-list-item
                    link
                    :to="to"
                    :href="url"
                    class="no-radius pl-8"
                    v-if="!hidden"
                    data-cy="menu-item">
                    <v-list-item-icon class="mr-0">
                      <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{text}} </v-list-item-title>
                    <v-list-item-action-text v-if="label">
                      <v-chip small label dark >
                        {{ label }}
                      </v-chip>
                    </v-list-item-action-text>
                  </v-list-item>
                </div>
              </v-list-group>
            </div>
            <v-divider></v-divider>

            <slot />
            <!-- other apps -->
            <v-list-item class="rounded-0 align-center" @click="manualToggle">
              <v-list-item-icon>
                <v-icon class="mt-1">
                  mdi-menu-open
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action>Zavřít menu</v-list-item-action>
              </v-list-item-content>
            </v-list-item>

            <v-menu min-width="105" offset-x>
               <template v-slot:activator="{ on, attrs }">
                 <v-list-item
                    v-bind="attrs"
                    v-on="on">
                   <v-list-item-icon>
                     <v-icon>
                       mdi-apps
                     </v-icon>
                   </v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-action class="ma-0">Ostatní služby</v-list-item-action>
                   </v-list-item-content>
               </v-list-item>
               </template>

                <v-card class="">
                  <v-list-item v-for="app in apps" :key="app.title" :href="app.link" target="_blank">
                    <v-list-item-icon>
                      <v-img :src="app.icon" width="50px" class="mx-auto"/>
                    </v-list-item-icon>
                    <v-list-item-subtitle >{{app.title}}</v-list-item-subtitle>
                  </v-list-item>
                </v-card>
            </v-menu>

            <!-- LOGOUT -->
            <v-list-item @click="logout" class="no-radius">
              <v-list-item-icon>
                <v-icon>
                  mdi-account-arrow-right-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-action>Odhlásit se</v-list-item-action>
              </v-list-item-content>
          </v-list-item>

      </v-layout>
      <v-icon class="mobile-menu cross sticky" :class="{'mini': isMinified}" @click.stop="toggleDrawer" large>mdi-close</v-icon>
      <div v-if="!minify" class="px-4 d-block production_version grey--text text--lighten-1">
          <p class="col-8 pa-0 mb-0 float-left">{{appInfo.shortName}} Verze: </p>
          <p class="col-4 pa-0 mb-0 float-right text-right">{{appVersion.buildDate}}</p>
          <p class="col-8 pa-0 mb-0 float-left">{{appVersion.hash}}</p>
          <p class="col-4 pa-0 mb-0 float-right text-right production_build-time">{{appVersion.buildTime}}</p>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>

  export default {
    name: 'SideMenuTemplate',
    data() {
      return {
        drawer: false,
        shouldHide: false,
        apps: [
          { title: 'Brani Sklad', icon: 'https://sklad.brani.cz/img/icons/favicon-32x32.png', link: `https://sklad.brani.cz`},
          { title: 'Brani Tisk', icon: 'https://tisk.brani.cz/img/icons/apple-touch-icon.png', link: `https://app.brani.cz`},
          { title: 'Brani Dopravy', icon: 'https://app.brani.cz/img/icons/apple-touch-icon.png', link: `https://app.brani.cz`},
        ],
      }
    },
    props: {
      appInfo: Object,
      menuItems: Array,
      minify: { type: Boolean, default: false },
    },
    watch: {
      drawer(value) {
        console.log(value);
        
      },
    },
    mounted() {
      if(window.innerWidth > 820){
        this.drawer = true;
      }
      const savedMinificationSetting = localStorage.getItem('sideMenuClosed');
      if (savedMinificationSetting !== null) this.drawer = savedMinificationSetting == 'false';

      window.addEventListener("resize", this.onResize);

    },
    destroyed() {
      window.removeEventListener("resize",  this.onResize);
    },
    computed: {
      enviromentMODE() {
        return process.env.NODE_ENV;
      },
      branchName() {
        return process.env.VUE_APP_BRANCH;
      },
      appVersion() {
        return { hash: process.env.VUE_APP_COMMIT_HASH, buildTime: process.env.VUE_APP_BUILD_TIME, buildDate: process.env.VUE_APP_BUILD_DATE };
      },
      isMinified: {
        get() { return this.minify && this.$vuetify.breakpoint.smAndUp },
        set() { }
      },
    },
    methods: {
      childrenNotHidden(subLinks) {
        return subLinks.filter(link => !link.hidden).length > 0
      },
      redirect(where){
        window.location.href = where;
      },
      onResize() {
        if(this.shouldHide == false && window.innerWidth < 820){
          this.shouldHide = true;
          this.drawer = false;
        }
        if(this.shouldHide == true && window.innerWidth > 820){
          this.shouldHide = false;
          this.drawer = true;
        }
      },
      toggleDrawer() {
        this.drawer = !this.drawer
      },
      logout() {
        this.$emit('logout');
      },
      manualToggle() {
        this.toggleDrawer();
        localStorage.setItem('sideMenuClosed', !this.drawer);
      }
    },
  }
</script>

<style scoped>

  .production_version {
    font-size: 12px;
    bottom: -0.9vh;
    width: 265px;
  }

  @media only screen and (max-width: 820px) {
    nav {
      z-index: 999;
      position: absolute;
      overflow: visible;
    }

    .v-navigation-drawer > div.v-navigation-drawer__content {
      overflow: visible !important;
    }
  }

  @media only screen and (min-width: 820px) {
    nav {
      background-color: transparent;
      position: initial;
    }
  }

  .mobile-menu {
    position: absolute !important;
    top: 15px;
    left: 0px;
    background-color: white;
    border-radius: 0px 12px 12px 0px;
    z-index: 999;
  }

  .cross {
    z-index: 999;
    height: 36px;
    width: 36px;
  }

  .sticky:not(.mini) {
    left: 265px;
    border-radius: 0px 3px 3px 0px;
  }

  .sticky.mini {
    left: 56px;
    border-radius: 0px 3px 3px 0px;
  }

  a.v-list-item--active {
    font-weight: bold;
  }

  .v-list-item .v-icon {
    margin-right: 15px;
  }

  .right-space {
    margin-right: 50px;
  }

  .no-width {
    width: 0px;
  }

</style>
