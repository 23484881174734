export default class Sound {
    playSound(sound) {
      if(localStorage.getItem('playSounds') === 'true') {
        const audio = new Audio(window.location.origin + this.getSoundFile(sound));
        audio.play();
      }
    }

    getSoundFile(sound) {
      const soundEnum = {
        "success" : "/sounds/correct_sound.wav",
        "error" : "/sounds/incorrect_sound.wav",
        "beep" : "/sounds/pipnuti.wav",
      };
      return soundEnum[sound];
    }
}

// Inject Auth instance as a Vue plugin
Sound.install = (Vue) => {
    const sound = new Sound();
    Vue.$sound= sound;
    Vue.prototype.$sound = sound;
};
