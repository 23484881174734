import { get, post, del, patch } from '@/services/api'

const state = {
  types: [],
  documents: [],
};

const getters= {
  getMovementTypes: state => state.types,
  getMovementNameByType: state => type => state.types.find(docType => docType.document_id == type),
  getDocuments: state => state.documents,
  getDocumentById: state => id => state.documents.find(doc => doc.document_id == id),
};

const actions= {
  fetchDocTypes({commit}) {
    get('/movement_documents/types/').then(res => {
      commit('SET_TYPES', res.data);
    });
  },

  fetchDocs({commit}) {
    return new Promise((resolve, reject) => {
      get('/movement_documents/').then(res => {
        commit('SET_DOCUMENTS', res.data);
        resolve()
      }).catch(() => {
        reject();
      })
    })
  },

  createDoc({commit}, data) {
    return new Promise((resolve, reject) => {
      post('/movement_documents/', data).then(res => {
        commit('ADD_DOCUMENT', res.data);
        resolve()
      }).catch(() => {
        reject();
      })
    })
  },

  updateDoc({dispatch}, data) {
    return new Promise((resolve, reject) => {
      patch(`/movement_documents/${data.document_id}`, data).then(() => {
        dispatch('fetchDocs');
        resolve()
      }).catch(() => {
        reject();
      })
    })
  },

  deleteDoc({dispatch}, rule_id) {
    return new Promise((resolve, reject) => {
      del(`/movement_documents/${rule_id}`).then(() => {
        dispatch('fetchDocs');
        resolve()
      }).catch(() => {
        reject();
      })
    })
  }
};

const mutations= {
  SET_TYPES: (state, types) => state.types = types,
  ADD_DOCUMENT: (state, doc) => state.documents.push(doc),
  SET_DOCUMENTS: (state, docs) => state.documents = docs,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
