export default class EshopConfig {
    constructor(store) {
        this.store = store;
    }

    get getConfig() {
      return this.store.getters.getEshopConfig;
    }

    imagePath() {
      if(this.store.getters.getEshopConfig.data)
        return this.store.getters.getEshopConfig.data.imageCuts.find(({name}) => name == "category").cdnPath;
      else
        return '';
    }

    isModuleActive(moduleName) {
      const modules = this.store.getters.getEshopConfig.modules;
      if(modules) {
        const index = modules.indexOf(moduleName);
        return index > -1;
      } else {
        return false;
      }
    }

    getMode() {
      return this.store.getters.getEshopConfig.stock_locations_mode;
    }

    getUsers() {
      return this.store.getters.getUserList;
    }

    getUserNameById(id) {
      const user = this.store.getters.getUserList.find(u => u.client_id == id);
      if(user) return user.client_name
      else return null;
    }

    getName() {
      const config = this.getConfig;
      if(config.data !== undefined) return config.data.contactInformation.eshopName;
      return '';
    }

    fetchStatus() {
      this.store.dispatch('fetchEshopStatus').then((res) => {
        if(res) {
          setTimeout( () => { this.fetchStatus() }, 5000);
        }
      });
    }

}

// Inject eshopConf instance as a Vue plugin
EshopConfig.install = (Vue, options) => {
    const eshopConf = new EshopConfig(options.store);
    Vue.$eshop = eshopConf;
    Vue.prototype.$eshop = eshopConf;
};
