export default class Sanitize {

    sanitizeText(html) {
        const allowedTags = ['b', 'font', 'dd', 'dl', 'dt', 'em', 'i', 'kbd', 'li', 'ol', 'p', 'pre', 's', 'sup', 'sub', 'strong', 'strike', 'ul'];
        const allowedAttributes = ['class', 'style', 'color'];

        const sanitizedHtml = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
                                        .replace(/<[^>]+>/g, (match) => {
                                          const tag = match.split(' ')[0].substring(1);
                                          if (allowedTags.includes(tag)) {
                                            let attributes = '';
                                            match.replace(/(\w+)=["']([^"']*)["']/g, (match, attribute, value) => {
                                              if (allowedAttributes.includes(attribute)) {
                                                attributes += `${attribute}="${value}" `;
                                              }
                                            });
                                            return `<${tag} ${attributes.trim()}>`;
                                          }
                                          return '';
                                        })
                                        .replace(/\n/g, ' <br/>');
        return sanitizedHtml;
     }

}

Sanitize.install = (Vue) => {
    const sanitize = new Sanitize();
    Vue.$sanitize= sanitize;
    Vue.prototype.$sanitize = sanitize;
};
