// ESLINT BUG - PRIVATE METHODS
/*eslint no-dupe-class-members: 0*/

import { post } from '@/services/api'

export default class RevertAction {

    #revertAO(item) {
       return new Promise((resolve) => {
           post(`/inventory/revert/${item.id}/`, {source: 'Reverzní záznam'}).then(() => {
              resolve();
           });
       });
     }

    movement(item) {
      return new Promise((resolve, reject) => {
        this.#revertAO(item)
            .then(() => resolve())
            .catch(() => reject());
       });
    }


}

RevertAction.install = (Vue) => {
    const revert = new RevertAction();
    Vue.$revert= revert;
    Vue.prototype.$revert = revert;
};
