import { post } from '@/services/api'

const state = {
  logs: [],
  pagination: {
    itemsPerPage: 25,
    next_id: null,
    previous_id: null,
  },
  productDetails: [],

  logsLoading: false,
};

const getters= {
  getLogs: state => state.logs,
  getLogsPagination: state => state.pagination,
  getProductById: state => code => state.productDetails.find(p => code == p[0].variant.code),
  getLogsLoading: state => state.logsLoading,
};

const actions= {
  fetchMovements ({commit}, data) {
    return new Promise((resolve) => {
      // This was implemented because the API response can take a bit longer and ngix returned index
      post('/log/', data || {}, {}, 'json', 60000).then((res) => {
        commit('SET_LOGS', res.data);
        resolve(res.data);
      })

    });
  },

  fetchProductDetail({ commit }, code) {
      return new Promise((resolve) => {
              post('/inventory/search/', { q: code }).then((res) => {
                commit("ADD_PRODUCT_DETAIL", res.data);
                resolve( res.data ); //Return product
              }).catch(() => {
                resolve( [] );
              });
      });
    },

    setLogLodaingStatus({commit}, status) {
      commit('SET_LOGS_LOADING', status);
    },
};

const mutations= {
  SET_LOGS: (state, logs) => {
    state.logs = logs.changes.map(l => {return {details: {}, ...l}});
    state.pagination.next_id = logs.next_id;
    state.pagination.previous_id = logs.previous_id;
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },
  ADD_PRODUCT_DETAIL: (state, item) => state.productDetails.push(item),
  SET_LOGS_LOADING: (state, status) => state.logsLoading = status,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
