<template>
    <div>
        <v-bottom-sheet v-model="showScanner" hide-overlay inset :persistent="isPersistent">
            <v-sheet id="camera-drawer" class="text-center camera-drawer" :class="{'pa-5': !activeCamera}">            
            <v-sheet v-if="showConfirm" class="scan-confirm-dialog text-center pa-5 mt-5">
                Byl naskenován kód: <strong>{{ decodedText }}</strong> chcete jej potvrdit?
                <div class="mt-3">
                    <v-btn color="error" text @click="resumeScanner()">Zrušit</v-btn>
                    <v-btn color="success" @click="manualConfirm()">Potvrdit</v-btn>
                </div>
            </v-sheet>
            <div class="camera-box">
                <div v-if="activeCamera" class="overlay-icon pa-2 d-flex">
                    <v-btn icon @click="changeCamera()"><v-icon color="white">mdi-camera-retake</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showScanner = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                </div>
                <div id="reader" height="200px" width="200px"></div>
            </div>
            
            <v-select v-if="!activeCamera" label="Vyberte kameru" :items="getCameraNames" v-model="activeCamera" @change="startScanner()"></v-select>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
export default {
    props: {
        show: Boolean,
    },
    data: () => ({
        virtualScanSettings: {
            enabled: false,
            mode: 'continious',
            persistent: false,
        },

        showDrawer: false,
        showScannedCode: false,
        showConfirm: false,

        cameraDevices: [],
        activeCamera: null,
        decodedText: "",

        scanningActive: true,
        scannerObject: null,

    }),
    watch: {
        showDrawer(val) {
            if(val == false && this.scannerObject !== null) {
                this.stopScanner();
            }
        }
    },
    mounted() {
        this.prepareScanner();

        const localVirtualScan = localStorage.getItem('virtualScanner')
        if(localVirtualScan) this.virtualScanSettings = JSON.parse(localVirtualScan); 

        const savedCameraGuid = localStorage.getItem('virtualScanCameraGuid')
        if(savedCameraGuid) {
            this.activeCamera = savedCameraGuid; 
            this.startScanner()
        }

    },
    destroyed() {
        this.stopScanner();
    },
    computed: {
        getCameraNames() {
            return this.cameraDevices.map(cam => { return {value: cam.id, text: cam.label }})
        },

        isPersistent() {
            return this.virtualScanSettings.persistent || this.virtualScanSettings.mode == 'continious'
        },

        showScanner: {
          get() {
            return this.show;
          },
          set() {
            this.stopScanner();
            this.$emit('close');
          },
        }
    },
    methods: {
        prepareScanner() {
            Html5Qrcode.getCameras().then(devices => {
                this.cameraDevices = devices;
            }).catch(err => {
                console.log(err);
                // handle err
            });
        },

        startScanner() {
            this.scannerObject = new Html5Qrcode("reader");
            localStorage.setItem('virtualScanCameraGuid', this.activeCamera)

            this.scanningActive = true;
            let box = document.querySelector('#camera-drawer');
            let width = box.offsetWidth;
            const aspectRatio = 9 / 16;
            const height = width * aspectRatio;

            this.scannerObject.start(
                this.activeCamera,
                {   
                    aspectRatio: 1.0,
                    fps: 2,    // Optional, frame per seconds for qr code scanning
                    qrbox: { width: width/2, height: height/2 }  // Optional, if you want bounded box UI
                },
                (decodedText) => {
                    this.decodedText = decodedText;
                    this.codeScanned()
                })
                .catch((err) => {
                    console.log(err);
                    // Start failed, handle it.
                });
        },
        codeScanned() {
            if(this.virtualScanSettings.mode == 'continious') {
                this.emitCode();
                this.pauseScanner();
                this.showScannedCode = true;
                setTimeout(() => this.showScannedCode = false, 1000);
                setTimeout(() => this.resumeScanner(), 1000);

            }

            if(this.virtualScanSettings.mode == 'confirm') {
                this.pauseScanner();
                this.showConfirm = true;
            }

            if(this.virtualScanSettings.mode == 'once') {
                this.emitCode();
                this.stopScanner();
                setTimeout(() => this.$emit('close'), 500);
                
            }
        },
        manualConfirm() {
            this.emitCode()
            this.resumeScanner()
        },
        emitCode() {
            this.showConfirm = false;
            this.$root.$emit('emulateScan', this.decodedText);
        },

        pauseScanner() {
            this.scanningActive = false;
            this.scannerObject.pause();
        },
        resumeScanner() {
            this.showConfirm = false;
            this.scanningActive = true;
            this.scannerObject.resume();
        },
        stopScanner() {
            this.scanningActive = false;
            this.scannerObject.stop();
            this.scannerObject = null;
        },
        changeCamera() {
            this.stopScanner()
            this.activeCamera = null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .scan-confirm-dialog {
        z-index: 9999;
        position: absolute;
        width: inherit;
    }

    .overlay-icon {
        position: absolute;
        width: 100%;
        z-index: 99999;
    }

    .camera-drawer {
        position:relative;
        max-height: 40vh;
    
    }
</style>