import { get } from '@/services/api'

const state = {
  config: {},
  eshopMode: "",
  users: [],
  stocks: [],
  eshopStatus: null,

  configLoaded: false,
};

const getters= {
  getEshopConfig: state => state.config,
  getEshopMode: state => state.eshopMode,
  getUserList: state => state.users,
  getUserById: state => id => state.users.find(user => user.client_id === id),
  getEshopStatus: state => state.eshopStatus,
  getOrgStocks: state => state.stocks,
  isPublicApi: state => state.config.is_public_api,
  isConfigLoaded: state => state.configLoaded,
};

const actions= {
  fetchEshopConfig ({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_CONFIG_LOADED', false);
      get('/eshop/').then((res) => {
        commit('SET_CONFIG', res.data);
        commit('SET_EAN_DELIMITERS', res.data.multiean_delimiters);
        commit('SET_SETTINGS', res.data.common_settings, { root: true });
        commit('SET_CONFIG_LOADED', true);
        resolve();
      }).catch(() => reject())
    });
  },

  fetchOrgStocks({commit}) {
    return new Promise((resolve, reject) => {
      get('/list_stocks/').then((res) => {
        commit('SET_STOCKS', res.data);
        resolve();
      }).catch(() => reject())
    });
  },

  fetchUserList ({commit}) {
    get('/clients/').then((res) => {
      commit('SET_USERS', res.data);
    })
  },

  fetchEshopStatus({commit}) {
    return new Promise((resolve) => {
      get('/status/').then((res) => {
        commit('SET_STATUS', res.data);
        if(res.data.sync) resolve(true);
        else resolve(false);
      })
    });
  },

};

const mutations= {
  SET_CONFIG: (state, conf) => { state.config = conf; state.eshopMode = conf.stock_locations_mode },
  SET_USERS: (state, users) => { state.users = users },
  SET_STATUS: (state, status) => { state.eshopStatus = status.sync },
  SET_STOCKS: (state, stocks) => state.stocks = stocks.map(stock => { return {value: stock.eshop_id, text: stock.eshop_name} }),
  SET_CONFIG_LOADED: (state, status) => state.configLoaded = status,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
