var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$slots.default
    ? _c(
        "v-alert",
        {
          attrs: {
            prominent: "",
            dismissable: _vm.allowClose,
            icon: _vm.icon,
            type: _vm.type,
          },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "grow" }, [_vm._t("default")], 2),
              _vm.allowClose
                ? _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [_c("v-btn", [_vm._v("Smazat")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }