export default class Snack {
    constructor(store) {
        this.store = store;
    }

    set(params) {
      this.store.dispatch('Snackbar/setSnack', params);
    }

    close() {
      this.store.dispatch('Snackbar/closeSnack');
    }

}

Snack.install = (Vue, options) => {
    const snack = new Snack(options.store);
    Vue.$snackbar = snack;
    Vue.prototype.$snackbar = snack;
};
