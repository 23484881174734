class ScanLoadingSpinner {
  constructor(store) {
    this.store = store;
  }

  open() {
    this.store.dispatch('ScanLoadingSpinner/setLoading');
  }

  close() {
    this.store.dispatch('ScanLoadingSpinner/closeLoading');
  }
}

export default {
  install(Vue, options) {
    const scanSpinner = new ScanLoadingSpinner(options.store);

    // Add to Vue prototype for global availability
    Vue.prototype.$scanSpinner = scanSpinner;

    // Optionally, if you want to provide it via the injection system
    Vue.mixin({
      beforeCreate() {
        this.$scanSpinner = scanSpinner;
      }
    });
  }
}
