import { patch } from '@/services/api'

const state = {
  chaosLocationData: [], // Products ready for loc. change in chaos
  locationToMoveFrom: null, // Location for location_change in chaos
  locationToMoveTo: null, // Location for to_location in chaos

  lastUpdateGuid: '',
};

const getters= {

  getChaosLocationData: state => state.chaosLocationData,
  getChaosLocationToMoveFrom: state => state.locationToMoveFrom,
  getChaosLocationToMoveTo: state => state.locationToMoveTo,
  getLastUpdateGuid: state => state.lastUpdateGuid,
};

const actions= {
  clearChaosLocations({ commit }) {
    commit('CLEAR_chaosLocationData')
    commit('CLEAR_locationToMoveFrom')
    commit('CLEAR_locationToMoveTo')
  },

  clearChaosLocationData({ commit }) {
    commit('CLEAR_chaosLocationData')
  },

  commitNewChaosLocations({ dispatch }, isDeposit) {
    return new Promise((resolve, reject) => {
            patch('/inventory/relocate/selective/', {products: state.chaosLocationData, source: 'Sklad - přesun', shoptet_sync: false}).then((res) => {
                  if(res.data.errors.length > 0) {
                    reject(res.data.errors);
                  } else {
                    state.lastUpdateGuid = crypto.randomUUID()
                    if(isDeposit) dispatch('recalculateSuggestedDeposit', state.chaosLocationData);
                    else dispatch('recalculateSuggestedCollect', state.chaosLocationData);

                    dispatch('clearScanning')
                    dispatch('clearChaosLocations')
                    resolve();
                  }
            }).catch((e) => reject(e));
    });
  },

};

const mutations= {
    ADD_chaosLocationData: (state, obj) => state.chaosLocationData.push(obj),
    CLEAR_chaosLocationData: (state) => state.chaosLocationData = [],
    SET_locationToMoveFrom: (state, code) => state.locationToMoveFrom = code,
    CLEAR_locationToMoveFrom: (state) => state.locationToMoveFrom = null,
    SET_locationToMoveTo: (state, code) => state.locationToMoveTo = code,
    CLEAR_locationToMoveTo: (state) => state.locationToMoveTo = null,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
