var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "no-width": !_vm.drawer } },
    [
      !_vm.drawer
        ? _c("v-app-bar-nav-icon", {
            staticClass: "mobile-menu",
            attrs: { large: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.manualToggle.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            id: "app-drawer",
            app: "",
            floating: "",
            width: "265px",
            "mini-variant": _vm.isMinified,
            height: _vm.$vuetify.breakpoint.mdAndUp ? "100%" : "100vh",
            "disable-resize-watcher": true,
            "mobile-breakpoint": "820",
          },
          on: {
            "update:miniVariant": function ($event) {
              _vm.isMinified = $event
            },
            "update:mini-variant": function ($event) {
              _vm.isMinified = $event
            },
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _vm.isMinified
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "pa-0 no-radius",
                      on: {
                        click: function ($event) {
                          return _vm.redirect("https://sklad.brani.cz")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "text-center d-block" },
                        [
                          _c("v-img", {
                            attrs: { src: "/img/brani-logo-white-center.png" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isMinified
                ? _c(
                    "v-list-item",
                    {
                      staticClass: "no-radius",
                      on: {
                        click: function ($event) {
                          return _vm.redirect("https://sklad.brani.cz")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "text-center d-block" },
                        [
                          _c("v-img", {
                            attrs: { src: "/img/brani-logo-white-center.png" },
                          }),
                          _c("v-list-item-action", [
                            _vm._v(" " + _vm._s(_vm.appInfo.name) + " "),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.appInfo.eshop) + " "),
                          ]),
                          _vm.enviromentMODE != "production"
                            ? _c("span", { staticClass: "warning--text" }, [
                                _vm._v(_vm._s(_vm.enviromentMODE)),
                              ])
                            : _vm._e(),
                          _vm.enviromentMODE != "production"
                            ? _c("p", { staticClass: "font-weight-bold" }, [
                                _vm._v("branch: "),
                                _c("span", { staticClass: "blue--text" }, [
                                  _vm._v(_vm._s(_vm.branchName)),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._t("top"),
              _vm._l(_vm.menuItems, function (link, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    !link.subLinks && !link.hidden
                      ? _c(
                          "v-list-item",
                          {
                            staticClass: "v-list-item rounded-r-xl",
                            attrs: {
                              to: link.to,
                              href: link.url,
                              exact: true,
                              "data-cy": "menu-item",
                            },
                          },
                          [
                            _c(
                              "v-list-item-action",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-5" },
                                  [_c("v-icon", [_vm._v(_vm._s(link.icon))])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(" " + _vm._s(link.text) + " "),
                            ]),
                            _c(
                              "v-list-item-action-text",
                              [
                                link.notification
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          small: "",
                                          color: "red",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(link.notification) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                link.label
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          small: "",
                                          label: "",
                                          dark: "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(link.label) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : !link.hidden && _vm.childrenNotHidden(link.subLinks)
                      ? _c(
                          "v-list-group",
                          {
                            attrs: { group: link.to || link.regex },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item-icon",
                                        { staticClass: "mr-1" },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(link.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { attrs: { "data-cy": "menu-group" } },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(link.text)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          _vm._l(link.subLinks, function (ref, i) {
                            var text = ref.text
                            var to = ref.to
                            var icon = ref.icon
                            var hidden = ref.hidden
                            var label = ref.label
                            var url = ref.url
                            return _c(
                              "div",
                              { key: i },
                              [
                                !hidden
                                  ? _c(
                                      "v-list-item",
                                      {
                                        staticClass: "no-radius pl-8",
                                        attrs: {
                                          link: "",
                                          to: to,
                                          href: url,
                                          "data-cy": "menu-item",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "mr-0" },
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(icon)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(text) + " "),
                                        ]),
                                        label
                                          ? _c(
                                              "v-list-item-action-text",
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      label: "",
                                                      dark: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(label) + " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c("v-divider"),
              _vm._t("default"),
              _c(
                "v-list-item",
                {
                  staticClass: "rounded-0 align-center",
                  on: { click: _vm.manualToggle },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { staticClass: "mt-1" }, [
                        _vm._v(" mdi-menu-open "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-action", [_vm._v("Zavřít menu")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "min-width": "105", "offset-x": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v(" mdi-apps ")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-action",
                                    { staticClass: "ma-0" },
                                    [_vm._v("Ostatní služby")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    {},
                    _vm._l(_vm.apps, function (app) {
                      return _c(
                        "v-list-item",
                        {
                          key: app.title,
                          attrs: { href: app.link, target: "_blank" },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-img", {
                                staticClass: "mx-auto",
                                attrs: { src: app.icon, width: "50px" },
                              }),
                            ],
                            1
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(app.title)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "no-radius", on: { click: _vm.logout } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", [
                        _vm._v(" mdi-account-arrow-right-outline "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-action", [_vm._v("Odhlásit se")])],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "v-icon",
            {
              staticClass: "mobile-menu cross sticky",
              class: { mini: _vm.isMinified },
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleDrawer.apply(null, arguments)
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
          !_vm.minify
            ? _c(
                "div",
                {
                  staticClass:
                    "px-4 d-block production_version grey--text text--lighten-1",
                },
                [
                  _c("p", { staticClass: "col-8 pa-0 mb-0 float-left" }, [
                    _vm._v(_vm._s(_vm.appInfo.shortName) + " Verze: "),
                  ]),
                  _c(
                    "p",
                    { staticClass: "col-4 pa-0 mb-0 float-right text-right" },
                    [_vm._v(_vm._s(_vm.appVersion.buildDate))]
                  ),
                  _c("p", { staticClass: "col-8 pa-0 mb-0 float-left" }, [
                    _vm._v(_vm._s(_vm.appVersion.hash)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "col-4 pa-0 mb-0 float-right text-right production_build-time",
                    },
                    [_vm._v(_vm._s(_vm.appVersion.buildTime))]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }