<template>
  <v-app style="background: #FFFFFF;">
    <ScanLoadingSpinner />
    <CameraScanner v-if="virtualScannerShow" :show="virtualScannerShow" @close="virtualScannerShow = false"/>

    <Alert v-if="getEshopStatus">
      Vyčkejte prosím, právě probíhá prvotní synchronizace. Aktuálně se nachází ve stavu: {{ getEshopStatus.stage }}
      (poslední aktualizace proběhla: {{ this.moment(getEshopStatus.last_update).format("DD-MM-YYYY HH:mm:ss") }} | zpracováno {{ getEshopStatus.counter || 0}} položek)
    </Alert>
    <NewVersionAlert :show="showUpdateSnack" @close="showUpdateSnack = false" @reload="reloadWorker()" />
    <Snackbar />
    <SnackbarV2 />
    <ConfirmDialog />
    <div id="app">
      <SideMenu :shopMode="getEshopMode"/>
      <router-view class="content" data-app/>
    </div>
  </v-app>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import SideMenu from '@/components/SideMenu'
  import Snackbar from '@/components/Snackbar'
  import SnackbarV2 from '@/components/Snackbar'
  import NewVersionAlert from '@/components/NewVersionAlert'
  import Auth from './services/Auth';

  import CameraScanner from './components/CameraScanner.vue'

  import Alert from '@/components/Alert'

  export default {
    name: 'app',
    components: {
      SideMenu,
      Snackbar,
      NewVersionAlert,
      Alert,
      CameraScanner,
      SnackbarV2
    },
    data:() => ({
      showUpdateSnack: false,
      newServiceWorker: null,

      virtualScannerShow: false,
    }),
    mounted() {
      this.$root.$on('openVirtualScanner', () => {
            this.virtualScannerShow = !this.virtualScannerShow;
        });
    },
    created() {
      //We load settings unless its being redirected to login on development
      if(location.pathname !== '/login') {
        Auth.initAuth().then(() => {
          const { client_id, client_name, org_id, org_name } = Auth.userInfo;

          this.$gtm.trackEvent({
            event: 'clarity', // The name of the custom event
            client_id, client_name, org_id, org_name 
          })

        })
      }

      /* PWA */
      if(process.env.NODE_ENV !== 'development') {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/serviceworker.js').then(reg => {
              if(reg.waiting) {
                // New worker is already waiting
                this.newServiceWorker = reg.waiting;
                this.showUpdateSnack = true;
              }
              // New version is available
              reg.addEventListener('updatefound', () => {
                this.newServiceWorker = reg.installing;
                this.newServiceWorker.addEventListener('statechange', () => {
                  if(this.newServiceWorker.state == 'installed') {
                    if (navigator.serviceWorker.controller) {
                      // New worker has been successfully installed
                      this.showUpdateSnack = true;
                    }
                  }
                });
              });
            });
            navigator.serviceWorker.addEventListener('controllerchange', function () {
               window.location.reload();
            });

            window.addEventListener('offline', () => {
                    this.setOnlineStatus(false);
                    this.$snackbar.set({text: 'Jste offline. Zkontrolujte připojení', icon: false, type:"error"});
            });
            window.addEventListener('online', () => {
                    this.setOnlineStatus(true);
                    this.$snackbar.set({text: 'Jste zpět online', icon: false, type:"info"});
            });
        }
      }
      /* END OF PWA */
    },
    computed: {
      ...mapGetters(['getEshopMode', 'getEshopStatus']),
      ...mapGetters('user', ['getOnlineStatus']),
    },
    methods: {
      ...mapActions([]),
      ...mapActions('user', ['setOnlineStatus']),
      reloadWorker() {
        this.showUpdateSnack = false;
        this.newServiceWorker.postMessage({ action: 'skipWaiting' });
      }
    },
  }

</script>
<style lang="scss">
    @import './styles/app.css';
  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-normal {
    cursor: default;
  }

  .cursor-move {
    cursor: move;
  }

  #app {
    font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    color: #2c3e50;
  }


  @media only screen and (min-width: 821px) {
      .content:not(.no-padding) {
          padding: 1.5rem 2.5rem 2.5rem 4rem;
      }
  }

  @media only screen and (max-width: 820px) {
      .content:not(.no-padding) {
          padding: 0.5rem 2rem;
      }
  }

  .content {
    width: 100%;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .image-zoomable.active {
    transform: scale(5);
    position: absolute;
    z-index: 9999;
    background-color: white;
    border: 1px solid rgba(200,200,200,0.3);
    transition: 350ms;

    .v-image__image {
      background-size: contain !important;
    }
  }

</style>
