import { get, post, del } from '@/services/api'

const state = {
  rules: [],
  currentRule: null,
};

const getters= {
  hasAnyRules: state => state.rules.length > 0,
  getCurrentCategoryId: state => state.currentRule,
  getCurrentCategory: state => state.rules.find(cat => cat.id == state.currentRule),
  
  getRelocationRulesCategories: state => state.rules.map(cat => { return { id: cat.id, name: cat.name, count: cat.data.length }}),
  getRelocationRulesFromCagetory: state => id => state.rules.find(cat => cat.id == id),
};

const actions= {

  fetchRelocationRules({commit}) {
    return new Promise((resolve, reject) => {
      get('/relocation_rules/list_rules/').then((res) => {
        resolve(res.data);

        if(!state.currentRule) commit('CHANGE_SELECTED_RULE', res.data[0].id)
        commit('SET_RELOCATION_RULES', res.data);
      }).catch(() => {
        reject();
      })
    })
  },

  updateRelocationRules({dispatch}, id) {
    return new Promise((resolve, reject) => {
      const index = state.rules.findIndex(cat => cat.id == id)
      const rules = state.rules[index];

      post('/relocation_rules/save/', rules).then((res) => {
        resolve(res.data);
        dispatch('fetchRelocationRules');
      }).catch((err) => {
        reject(err);
      })
    });
  },

  createNewCategory({dispatch}, name) {
    return new Promise((resolve, reject) => {

      post('/relocation_rules/save/', { name, data: [] }).then((res) => {
        resolve(res.data);
        dispatch('fetchRelocationRules');
      }).catch((err) => {
        reject(err);
      })
    });
  },

  deleteCategory({dispatch}, categoryId) {
    return new Promise((resolve, reject) => {

      del(`/relocation_rules/delete/${categoryId}/`).then((res) => {
        if(res.status == 200) {
          dispatch('fetchRelocationRules');
          resolve(res.data);
        }
        else reject(res.data)
        
      }).catch((err) => {
        reject(err.payload.detail);
      })
    });
  },

};

const mutations= {
  SET_RELOCATION_RULES: (state, rules) => state.rules = rules,
  
  CHANGE_RULE_ORDER: (state, {id, data}) => {
    const index = state.rules.findIndex(cat => cat.id == id);
    state.rules[index].data = data; 
  },
  ADD_RELOCATION_RULE: (state, rule) => {
    const catIndex = state.rules.findIndex(cat => cat.id == state.currentRule);
    state.rules[catIndex].data.unshift(rule);
  },
  ADD_MULTIPLE_RULES: (state, {rules, removeOld}) => {
    if(removeOld)
      state.rules = rules
    else
      state.rules.push(...rules)
  },
  CHANGE_RELOCATION_RULE: (state, {rule, index}) => {
    const catIndex = state.rules.findIndex(cat => cat.id == state.currentRule);
    state.rules[catIndex].data[index] = rule; 
  },
  UPDATE_CATEGORY_RULES: (state, newRules)  => {
    const catIndex = state.rules.findIndex(cat => cat.id == state.currentRule);
    state.rules[catIndex].data = newRules
  },
  REMOVE_RELOCATION_RULE: (state, index) => {
    const catIndex = state.rules.findIndex(cat => cat.id == state.currentRule);
    state.rules[catIndex].data.splice(index, 1);
  },

  CHANGE_SELECTED_RULE: (state, id) => state.currentRule = id,
  CHANGE_CATEGORY: (state, category) => {
    const catIndex = state.rules.findIndex(cat => cat.id == category.id);
    state.rules[catIndex] = category;
  }
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
