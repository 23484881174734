import { get } from '@/services/api'

const state = {
  collect: [],
  deposit: [],

  loading: false,
  lastUpdate: null,
  temporary: false,

  selectedCategoryId: null,
};

const getters= {
  getSuggestedToCollect: state => state.collect,
  getSuggestedToDeposit: state => state.deposit,
  isSuggestedMovesLoading: state => state.loading,
  getLastUpdateTime: state => state.lastUpdate,
  isTemporaryState: state => state.temporary,

  getSuggestCategoryId: state => state.selectedCategoryId,
};

const actions= {

  fetchSuggestedToCollect({commit, rootGetters, getters}) {
    commit('SET_SUGGESTED_MOVES_LOADING', true)
    new Promise((resolve) => {
      const prefetchGuid = rootGetters.getLastUpdateGuid.slice()
      const categoryId = getters.getSuggestCategoryId
      if(categoryId == null) {
        commit('SET_SUGGESTED_MOVES_LOADING', false)
        resolve([])
        return; 
      }
 
      get(`/relocation_rules/suggestions/${categoryId}/collect/`).then(res => {
          if(typeof res.data == 'string') resolve();
        //Test if response data are still valid
          if(prefetchGuid == rootGetters.getLastUpdateGuid) {
            state.lastUpdate = new Date()
            commit('SET_SUGGESTED_COLLECT', res.data)
            commit('SET_IS_TEMPORARY_STATE', false)
            commit('SET_SUGGESTED_MOVES_LOADING', false)
            resolve(res.data);
          } else {
            resolve([])
          }
      }).catch(() => {
        commit('SET_SUGGESTED_COLLECT', [])
        commit('SET_SUGGESTED_MOVES_LOADING', false)
      })
    })
  },

  fetchSuggestedToDeposit({commit, rootGetters}) {
    commit('SET_SUGGESTED_MOVES_LOADING', true)
    new Promise((resolve) => {
      const prefetchGuid = rootGetters.getLastUpdateGuid.slice()

      get('/relocation_rules/suggestions/deposit/').then(res => {
        //Test if response data are still valid
        if(prefetchGuid == rootGetters.getLastUpdateGuid) {
          state.lastUpdate = new Date()
          commit('SET_IS_TEMPORARY_STATE', false)
          commit('SET_SUGGESTED_MOVES_LOADING', false)

          commit('SET_SUGGESTED_DEPOSIT', res.data)
          resolve(res.data)
        } else {
          resolve([])
        }
      }).catch(() => {
        commit('SET_SUGGESTED_MOVES_LOADING', false);
      })
    })
  },

  //COLLECT 
  recalculateSuggestedCollect({commit}, productArray) {
    let newCollectArray = state.collect;

    productArray.reduce(async (prev, product) => {
      await prev;
      //Search for matching suggestion
      const fromLocationsArray = product.from_locations.map(loc => loc.location)
      const match = newCollectArray.find(productToCollect => productToCollect.code == product.code && fromLocationsArray.includes(productToCollect.location_name) )
      if(match) {
        //Find the coresponding location object and take the amount from it
        const productLocationObject = product.from_locations.find(l => l.location == match.location_name)
        match.amount_to_collect -= productLocationObject.amount;
        const moveIndex = state.collect.indexOf(match)

        //Delete it in case it's 0
        if(match.amount_to_collect <= 0) {
          newCollectArray.splice(moveIndex, 1)
        } else {
          newCollectArray[moveIndex] = match
        }
      }
    }, Promise.resolve())
    commit('SET_COLLECT', newCollectArray)
    commit('SET_IS_TEMPORARY_STATE', true)
  
  },

  //DEPOSIT
  recalculateSuggestedDeposit({commit}, productArray) {
    let newDepositArray = state.collect;

    productArray.reduce(async (prev, product) => {
      await prev;
      //Search for matching suggestion
      const match = newDepositArray.find(productToCollect => productToCollect.code == product.code && product.to_location == productToCollect.location_name )
      if(match) {
        //Take the locations object and take the amount from it
        match.amount_to_deposit -= product.from_locations[0].amount;
        const moveIndex = state.collect.indexOf(match)

        //Delete it in case it's 0
        if(match.amount_to_deposit <= 0) {
          newDepositArray.splice(moveIndex, 1)
        } else {
          newDepositArray[moveIndex] = match
        }
      }
    }, Promise.resolve())
    commit('SET_COLLECT', newDepositArray)
    commit('SET_IS_TEMPORARY_STATE', true)
  
  },

  refreshSuggested({commit}) {
    commit('SET_SUGGESTED_COLLECT', state.collect);
  },

};

const mutations= {
  SET_SUGGESTED_COLLECT: (state, data) => {
    data = data.map((d, i) => ({...d, index:i, details: [], hidden: false}));
    const hiddenProducts = JSON.parse(localStorage.getItem('hiddenSuggestions'));
    if(hiddenProducts !== null) {
      const shouldBeHidden = data.filter(d => hiddenProducts.some(h => h.code === d.code && h.location === d.location_id));
      const notInHidden = data.filter(d => shouldBeHidden.indexOf(d) == -1);
      const injectHiddenTag = shouldBeHidden.map(h => ({...h, hidden: true}));

      state.collect = [...notInHidden, ...injectHiddenTag];
    } else {
      state.collect = data;
    }
  },

  SET_COLLECT: (state, data) => state.collect = data,

  SET_SUGGESTED_DEPOSIT: (state, data) => {
    state.deposit = data.map((d, i) => ({...d, index:i, details: []}))
  },

  SET_SUGGESTED_MOVES_LOADING: (state, status) => state.loading = status,
  SET_IS_TEMPORARY_STATE: (state, temporary) => state.temporary = temporary,

  CHANGE_SUGGESTION_CATEGORY: (state, id) => state.selectedCategoryId = id,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
