<template>
  <h2 class="text-center text-sm-left"><slot /><span class="text--grey text-lighten-2">{{eshop}}</span></h2>
</template>

<script>

export default {
  name: 'PageTitle',
   props: { eshop: String },
}
</script>

<style scoped>
  h2 span {
    font-size: 20px;
  }
</style>
