import { get, post, del, patch } from '@/services/api'

const state = {
  multiean: [],
  multieanErrors: [],
  multiEanLoading: false,
  eanDelimiters: {
    char: [],
    num: null,
  },
};

const getters= {
  getMultiEan: state => state.multiean,
  getMultieanErrors: state => state.multieanErrors,
  getMultiEanLoading: state => state.multiEanLoading,
  getEanDelimiters: state => state.eanDelimiters,
};

const actions= {
  fetchMultiEan({commit}) {
    commit('SET_MULTIEAN_LOADING',  true);
    get('/inventory/custom_products/').then(res => {
      commit('SET_MULTIEAN', res.data);
      commit('SET_MULTIEAN_LOADING',  false);
    })
  },

  deleteMultiEan({commit}, id) {
    del(`/inventory/custom_products/?id=${id}`).then(res => {
      commit('SET_MULTIEAN', res.data);
    })
  },

  updateMultiEan({commit}, {ean, amount, code, id}) {
    new Promise((resolve, reject) => {
      patch(`/inventory/custom_products/?custom_id=${id}`, {ean, amount, code}).then(res => {
        commit('SET_MULTIEAN', res.data);
        resolve();
      }).catch(() => {
        reject();
      })
    })
  },

  createMultiEan({commit}, {ean, amount, code}) {
    new Promise((resolve) => {
      post(`/inventory/custom_products/`, {ean, amount, code}).then(res => {
        commit('SET_MULTIEAN', res.data);
        resolve();
      }).catch(() => {
        commit('SET_MULTIEAN_ERROR', ean);
        resolve();
      })
    })
  },

  updateEanDelimiters({commit}, delimiters) {
    new Promise((resolve) => {
      post(`/update_multiean_delimiters_settings/`, delimiters).then(() => {
        commit('SET_EAN_DELIMITERS', delimiters);
        resolve();
      })
    })
  }

};

const mutations= {
  SET_MULTIEAN_LOADING: (state, status) => state.multiEanLoading = status,
  SET_MULTIEAN: (state, data) => state.multiean = data,
  SET_MULTIEAN_ERROR: (state, ean) => state.multieanErrors.push(`EAN ${ean} již existuje`),

  CLEAR_MULTIEAN_ERRORS: (state) => state.multieanErrors = [],
  SET_EAN_DELIMITERS: (state, delimiters) => { if(delimiters != null) state.eanDelimiters = delimiters },
  SET_EAN_CHAR_DELIMITERS: (state, delimiters) => state.eanDelimiters.char = delimiters,
  SET_EAN_NUM_DELIMITERS: (state, delimiter) => state.eanDelimiters.num = delimiter,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
