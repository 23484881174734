<template>
    <v-tooltip :open-delay="delay" bottom :max-width="width">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot/>
        </span>
      </template>
      <p class="tip__body mb-0">
        <span v-if="tip">{{tip}}</span>
        <slot v-else name="tip"/>
      </p>
    </v-tooltip>
</template>

<script>
export default {
  name: 'OneLineTooltip',
  props: {
    tip: { type: String },
    width: { type: String, default: 'auto'},
    delay: { type: String, default: '0' },
  }
}
</script>

<style lang="css" scoped>
  .tip__body {
    line-height: normal;
  }

</style>
