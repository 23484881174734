import { render, staticRenderFns } from "./ConfirmDialog.vue?vue&type=template&id=055d7028&scoped=true&"
import script from "./ConfirmDialog.vue?vue&type=script&lang=js&"
export * from "./ConfirmDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055d7028",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VDivider,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/worker/workspace/infra__skladovy_system-frontend_builder-FE/brani-warehouse/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('055d7028')) {
      api.createRecord('055d7028', component.options)
    } else {
      api.reload('055d7028', component.options)
    }
    module.hot.accept("./ConfirmDialog.vue?vue&type=template&id=055d7028&scoped=true&", function () {
      api.rerender('055d7028', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ConfirmDialog.vue"
export default component.exports