const { get } = require('@/services/api');

const state = {
    clientPermissions: {},
};

const getters= {
  getClientPermissions: state => state.clientPermissions,
  getSettingsFE: state => state.clientPermissions.settings_fe || {},
  clientHasPermission: (state, getters) => (permission) => {
    if(!getters.getClientPermissions.forbidden_scopes || getters.getClientPermissions.forbidden_scopes.length == 0) return true;
    const forbidden = getters.getClientPermissions.forbidden_scopes?.includes(permission);
    const allowed = getters.getClientPermissions.allowed_scopes?.includes(permission);
    return allowed || !forbidden;
  },
  isBlacklistedUrl: (state, getters) => currentUrl => {
    const forbiddenUrls = getters.getClientPermissions.blacklist_urls;
    return forbiddenUrls ? forbiddenUrls.some(url => currentUrl.includes(url)) : false;
  },
  isAppAllowed: (state, getters) => {
    if(!getters.getSettingsFE.allowed_apps || getters.getSettingsFE.allowed_apps.length == 0) return true;
    return getters.getSettingsFE.allowed_apps ? getters.getSettingsFE.allowed_apps.includes('Brani Sklad') : true;
  },
};

const actions= {
  fetchClientPermissions({commit, rootGetters}) {
    const APP_URL = process.env.VUE_APP_PRINT_SERVER;
    const roleId = rootGetters['User/getClientInfo'].role_id;
    if(!roleId) return;
  
    get(`${APP_URL}/user/roles/list/`).then((res) => {
      const role = res.data.find(role => role.id === roleId);
      commit('SET_CLIENT_PERMISSIONS', role);
    });
  }
};

const mutations= {
  SET_CLIENT_PERMISSIONS: (state, permissions) => state.clientPermissions = permissions,
};

export default {
  namespaced: true,
  name: 'permissions',
  state,
  getters,
  actions,
  mutations,
}
