<template>
  <SideMenuTemplate :appInfo="createAppInfo" :menuItems="menuItems" @logout="logout()">
    <template #top>
      <v-list-item v-if="getOrgStocks.length > 1">
        <v-list-item-content>
          <v-select label="Sklad" :value="getCurrentStock" :items="getOrgStocks" dense @change="changeCurrentStock"></v-select>
        </v-list-item-content>
      </v-list-item>
    </template>
  </SideMenuTemplate>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SideMenuTemplate from '@/components/SideMenuTemplate'
  import Auth from '@/services/Auth';
  import { get } from '@/services/api'

  export default {
    name: 'SideMenu',
    components: {
      SideMenuTemplate,
    },
    props: {
      shopMode: String,
    },
    data: () => ({
      languages: ['cs', 'en']
    }),
    computed: {
      ...mapGetters(['getOrgStocks']),
      ...mapGetters('Permissions', ['clientHasPermission']),
      getCurrentStock() {
        return Auth.userParameter('stock_eshop_id');
      },
      createAppInfo() {
        return {
          name: 'Skladový systém',
          eshop: this.$eshop.getConfig.org_name,
          shortName: 'Brani Sklad',
        }
      },
      menuItems() {
        if(!this.getOrgStocks.length) return [];
        return [
          {
            to: '/documents/receipt',
            icon: 'mdi-shipping-pallet',
            text: 'Příjmové doklady',
            hidden: !this.allowedEntry('sklad.documents'),
          },
          {
            to: '/documents/issue',
            icon: 'mdi-shipping-pallet',
            text: 'Výdajové doklady',
            hidden: !this.allowedEntry('sklad.documents'),
          },
          {
            to: '/products/add',
            text: 'Naskladnění',
            icon: 'mdi-plus-box-multiple',
            hidden: !this.allowedEntry('sklad.naskladneni'),
          },
          {
            to: '/products/add-ao',
            text: 'Naskladnění ze seznamu',
            icon: 'mdi-plus-box-multiple',
            hidden: !this.moduleActive('auto-order') || !this.allowedEntry('sklad.naskladneni_ao'),
          },
          {
            to: '/products/remove',
            text: 'Vyskladnění',
            icon: 'mdi-minus-box-multiple',
            hidden: !this.allowedEntry('sklad.vyskladneni'),
          },
          {
            to: '/products/no_sync_add',
            text: 'Naskladneni vratek/pozic',
            icon: 'mdi-clipboard-plus',
            hidden: this.shopMode != 'chaos' || this.$eshop.getConfig.is_public_api == true || !this.allowedEntry('sklad.naskladneni_vratek'),
          },
          {
            to: '/products/no_sync_remove',
            text: 'Vyskladneni vratek/pozic',
            icon: 'mdi-clipboard-minus',
            hidden: this.shopMode != 'chaos' || this.$eshop.getConfig.is_public_api == true || !this.allowedEntry('sklad.vyskladneni_vratek'),
          },
          {
            to: '/products/location_change',
            text: 'Změna pozice',
            icon: 'mdi-ballot-recount',
            hidden: this.shopMode == 'chaos' || !this.allowedEntry('sklad.zmena_pozic'),
          },
          {
            to: '/locations/chaos_change',
            text: 'Změna pozice',
            icon: 'mdi-ballot-recount',
            hidden: this.shopMode == 'basic' || !this.allowedEntry('sklad.zmena_pozic'),
          },
          {
            to: '/products/location_check',
            text: 'Ověření Pozice',
            icon: 'mdi-magnify',
            hidden: !this.allowedEntry('sklad.overeni_pozice'),
          },
          {
            text: 'Další nástroje',
            icon: 'mdi-archive-settings-outline',
            regex: /(stocktaking)|(inventory\/(overview|layout))|(exports)|(eans)|(printing)|(locations\/relocation_rules)|(deleted_products)|(product_groups)|(product_diffs)/,
            subLinks: [
              {
                to: '/stocktaking',
                icon: 'mdi-archive-edit-outline',
                text: 'Inventura',
                hidden: !this.allowedEntry('sklad.inventura'),
              },
              {
                to: '/inventory/overview',
                text: 'Náhled na sklad',
                icon: 'mdi-archive-search',
                hidden: !this.allowedEntry('sklad.nahled_na_sklad'),
              },
              {
                to: '/inventory/layout',
                text: 'Reorganizace skladu',
                icon: 'mdi-archive-refresh',
                hidden: !this.allowedEntry('sklad.reorganizace_skladu'),
              },
              {
                to: '/locations/relocation_rules',
                text: 'Pravidla pro přesun',
                icon: 'mdi-format-list-checks',
                hidden: this.shopMode == 'basic' || !this.allowedEntry('sklad.pravidla_pro_presun'),
              },
              {
                to: '/eans',
                text: 'Multieany a krabice',
                icon: 'mdi-barcode',
                hidden: !this.allowedEntry('sklad.multiean_krabice'),
              },
              {
                to: '/printing',
                text: 'Tisk štítků',
                icon: 'mdi-printer-settings',
                hidden: !this.allowedEntry('sklad.tisk_stitku'),
              },
              {
                to: '/exports',
                text: 'Export/Import',
                icon: 'mdi-file-arrow-up-down-outline',
                hidden: !this.allowedEntry('sklad.export_import'),
              },
              {
                to: '/product_diffs',
                text: 'Nesrovnalosti',
                icon: 'mdi-delete-sweep-outline',
                hidden: !this.allowedEntry('sklad.nesrovnalosti'),
              }
            ]
          },
          {
            to: '/movementLog',
            icon: 'mdi-clipboard-flow-outline',
            text: 'Log pohybů',
            hidden: !this.allowedEntry('sklad.log_pohybu'),
          },
          {
            to: '/settings',
            icon: 'mdi-cog',
            text: 'Nastavení',
            hidden: !this.allowedEntry('sklad.nastaveni'),
          }
        ]
      },
    },
    methods: {
      moduleActive(moduleName) {
        return this.$eshop.isModuleActive(moduleName);
      },
      allowedEntry(permissions){
        return this.clientHasPermission(permissions);
      },
      logout() {
        Auth.logout();
      },
      changeCurrentStock(id) {
        get(`${Auth.authUrl.be}/set_stock_eshop/?stock_eshop_id=${id}`).then(async res => {
          const isDev = location.host.includes('localhost');
          if(isDev) await Auth.setDevToken({token: res.data.access_token, expire: res.data.access_token_expire, hash: res.data.token_hash})
          location.reload();
        }).catch(err => {
          this.$snackbar.set({text: err.payload.detail, type: 'error', timeout: 2000});
        })
      },
    }
  }
</script>

<style lang="css" scoped>
</style>
