import { get } from '@/services/api'

const state = {
  globalDefaults: {},
  manualPrintSettings: {
    printer: null,
    productTemplate: null,
    locationTemplate: null,
  },
  autoPrintSettings: {
    product: false,
    productSingle: false,
    productPrinter: null,
    productTemplate: null,
    location: false,
    locationTemplate: null,
    locationPrinter: null,
  },
};

const getters= {
  getDefaultGlobals: state => state.globalDefaults,
  getManualPrinter: state => state.manualPrintSettings.printer,
  getManualSettings: state => state.manualPrintSettings,
  getAutoSettings: state => state.autoPrintSettings,
};

const actions= {

  autoUseGlobalDefaults({commit, getters}) {
    if(state.globalDefaults)
      commit('SET_AUTO_SETTINGS', getters.getDefaultGlobals.automated)
  },

  manualUseGlobalDefaults({commit, getters}) {
    if(state.globalDefaults)
      commit('SET_MANUAL_SETTINGS', getters.getDefaultGlobals.manual)
  },

  loadPrintSettings({commit}) {
    let eshopDefaults;
    // LOAD ESHOP GLOBALS
    const autoLocalSettings = JSON.parse(localStorage.getItem('autoPrint'))
    const manualLocalSettings = JSON.parse(localStorage.getItem('manualPrint'))

    const autoOverwriteDefaults = localStorage.getItem('autoPrintUseDefaults')
    const useManualDefaults = localStorage.getItem('manualPrintUseDefaults')

    get('/eshop/').then((res) => {
      if(res.data.print_settings)
        eshopDefaults = res.data.print_settings
        commit('SET_GLOBAL_SETTINS', eshopDefaults)

      // MANUAL PRINT
      // Overwrite is set we use globals and overwrite them
      if(useManualDefaults === 'true') {
        let manual = {};
        // Use defaults
        if(eshopDefaults) {
           manual = eshopDefaults.manual
        }
        // Replace printer with local settings
        if(manualLocalSettings !== null) {
           manual.printer = manualLocalSettings.printer;
        }
        commit('SET_MANUAL_SETTINGS', manual)
      }
      // Overwrite is not set we try using local settings and if they don't exist we use globals
      if(useManualDefaults !== 'true' && manualLocalSettings !== null) {
        commit('SET_MANUAL_SETTINGS', manualLocalSettings);
      } else if(eshopDefaults && eshopDefaults.manual){
        commit('SET_MANUAL_SETTINGS', eshopDefaults.manual);
      }


      // AUTO PRINT
      // Overwrite is set we use globals and overwrite them
      if(autoOverwriteDefaults === 'true') {
        let auto = {};
        // Use defaults
        if(eshopDefaults) {
           auto = eshopDefaults.automated;
        }
        // Replace printer with local settings
        if(autoLocalSettings !== null) {
            if(autoLocalSettings.productPrinter)
              auto.productPrinter = autoLocalSettings.productPrinter;
            if(autoLocalSettings.locationPrinter)
              auto.locationPrinter = autoLocalSettings.locationPrinter;
        }
        commit('SET_AUTO_SETTINGS', auto)
      }
      // Overwrite is not set we try using local settings and if they don't exist we use globals
      if(useManualDefaults !== 'true' && autoLocalSettings !== null) {
        commit('SET_AUTO_SETTINGS', autoLocalSettings);
      } else if(eshopDefaults && eshopDefaults.automated){
        commit('SET_AUTO_SETTINGS', eshopDefaults.automated);
      }

    })
  },

};

const mutations= {
  SET_MANUAL_SETTINGS: (state, data) => state.manualPrintSettings = data,
  SET_AUTO_SETTINGS: (state, data) => state.autoPrintSettings = data,
  SET_GLOBAL_SETTINS: (state, data) => state.globalDefaults = data,
};

///// TODO: make this namespaced


export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
