import { get } from '@/services/api'

const PRINT_API_URL = process.env.VUE_APP_PRINT_API;

const state = {
  templates: [],
};

const getters= {
  getTemplates: state => state.templates,
  getProductTemplatesList: state => state.templates.filter(t => t.type.type == 'shoptet-product').map(t => ({ text: t.name , value: t.id})),
  getLocationTemplatesList: state => state.templates.filter(t => t.type.type == 'stock-position').map(t => ({ text: t.name , value: t.id})),
  getTemplateById: state => id => state.templates.find(t => t.id == id),
};

const actions= {
  fetchTemplates({commit}) {
    return new Promise((resolve, reject) => {
      get(PRINT_API_URL + '/template/').then((res) => {
        commit('SET_TEMPLATES', res.data);
        resolve();
      }).catch(() => reject())
    });
  },


};

const mutations= {
  SET_TEMPLATES: (state, tempaltes) => state.templates = tempaltes,
  ADD_TEMPLATES: (state, tempalte) => state.templates.push(tempalte),
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
