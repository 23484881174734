<template>
  <v-alert
      v-if="this.$slots.default"
      prominent
      :dismissable="allowClose"
      :icon="icon"
      :type="type">
      <v-row align="center">
        <v-col class="grow">
          <slot />
        </v-col>
        <v-col class="shrink" v-if="allowClose">
          <v-btn>Smazat</v-btn>
        </v-col>
      </v-row>
    </v-alert>
</template>

<script>
  export default {
    name: 'Alert',
    mounted() {
      window.scrollTo(0, 0); //Chrome will remember scroll after F5 and won't show the alert.
    },
    props: {
      type: {type: String, default: 'error' },
      allowClose: {type: Boolean, default: false },
      icon: {type: String }, //
    },
  }
</script>

<style scoped>
</style>
