const state = {
  displayDialog: {},
  confirmFunc: {},
  cancelFunc: {},
};

const getters= {
  getDialog: state => state.displayDialog,
  getConfirm: state => state.confirmFunc,
  getCancel: state => state.cancelFunc,
};

const actions= {

  setDialog({commit}, {params, confirm, cancel}) {
    commit('SET_DIALOG', params);
    commit('SET_CONFIRM_FUNC', confirm);
    commit('SET_CANCEL_FUNC', cancel);
  },

  closeDialog({commit}) {
    commit('DEL_DIALOG');
  },
};

const mutations= {
  SET_DIALOG: (state, params) => state.displayDialog = params,
  SET_CONFIRM_FUNC: (state, func) => state.confirmFunc = func,
  SET_CANCEL_FUNC: (state, func) => state.cancelFunc = func,
  DEL_DIALOG: (state) => {
    state.displayDialog = {}
    state.confirmFunc = {}
    state.cancelFunc = {}
  }
};

export default {
  namespaced: true,
  name: 'dialog',
  state,
  getters,
  actions,
  mutations,
}
