import Vue from 'vue';

const pluralizeCZ = (text, amount) => {
  if(amount == 1) return `${text}`
  if(amount >= 2 && amount <= 4) return `${text}y`
  if(amount == 0 || amount >= 5) return `${text}ů`
};

const pluralizeWithOptions = (text, amount, pluralizations = []) => {
  if(amount == 1) return `${text}${pluralizations[0]}`
  if(amount >= 2 && amount <= 4) return `${text}${pluralizations[1]}`
  if(amount == 0 || amount >= 5) return `${text}${pluralizations[2]}`
};

Vue.filter('pluralizeCZ', pluralizeCZ);
Vue.filter('pluralizeWithOptions', pluralizeWithOptions);
