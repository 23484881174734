import { get } from '@/services/api'

const PRINT_API_URL = process.env.VUE_APP_PRINT_API;

const state = {
  printers: [],

};

const getters = {
  getPrinters: state => state.printers.filter(p => p.is_visible === true),
  getPrinterById: state => id => state.printers.find(p => p.id === parseInt(id)),
  getPrintersList: state => {
    const printers = state.printers.filter(p => p.is_visible === true && p.is_active === true && p.is_present === true)
    return printers.map(p => ({ text: p.name || p.identifier, value: p.id}))
  }

};

const actions= {
  fetchPrinters({commit}) {
    return new Promise((resolve, reject) => {
      get(PRINT_API_URL + '/printers/').then((res) => {
        commit('SET_PRINTERS', res.data);
        resolve();
      }).catch(() => reject())
    });
  },

};

const mutations= {
  SET_PRINTERS: (state, printers) => state.printers = printers,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
