var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { background: "#FFFFFF" } },
    [
      _c("ScanLoadingSpinner"),
      _vm.virtualScannerShow
        ? _c("CameraScanner", {
            attrs: { show: _vm.virtualScannerShow },
            on: {
              close: function ($event) {
                _vm.virtualScannerShow = false
              },
            },
          })
        : _vm._e(),
      _vm.getEshopStatus
        ? _c("Alert", [
            _vm._v(
              " Vyčkejte prosím, právě probíhá prvotní synchronizace. Aktuálně se nachází ve stavu: " +
                _vm._s(_vm.getEshopStatus.stage) +
                " (poslední aktualizace proběhla: " +
                _vm._s(
                  this.moment(_vm.getEshopStatus.last_update).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )
                ) +
                " | zpracováno " +
                _vm._s(_vm.getEshopStatus.counter || 0) +
                " položek) "
            ),
          ])
        : _vm._e(),
      _c("NewVersionAlert", {
        attrs: { show: _vm.showUpdateSnack },
        on: {
          close: function ($event) {
            _vm.showUpdateSnack = false
          },
          reload: function ($event) {
            return _vm.reloadWorker()
          },
        },
      }),
      _c("Snackbar"),
      _c("SnackbarV2"),
      _c("ConfirmDialog"),
      _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("SideMenu", { attrs: { shopMode: _vm.getEshopMode } }),
          _c("router-view", {
            staticClass: "content",
            attrs: { "data-app": "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }