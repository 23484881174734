<template>
  <v-snackbar v-model="showUpdateSnack" content-class="pb-0" timeout="-1" right vertical multi-line min-width="150px">
    🚀 K dispozici je nová verze aplikace. Chcete ji načíst?
   <template v-slot:action="{ attrs }">
     <v-btn
       class="grey--text"
       v-bind="attrs"
       text
       small
       @click="showUpdateSnack = false">
       Později
     </v-btn>
     <v-btn
       class="info--text"
       v-bind="attrs"
       text
       @click="$emit('reload')">
       Ano
     </v-btn>
   </template>
 </v-snackbar>
</template>

<script>
export default {
  name: 'NewVersionAlert',
  props: {
    show: { type: Boolean, default: false }
  },
  computed: {
    showUpdateSnack: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close');
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
