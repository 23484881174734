import { get, post, del } from "@/services/api";

const state = {
  stockTakings: [],
  inventoryChanges: [],

  inventoryChangesDetails: [],
  pagination: { page: 1, per_page: 50, total_pages: 1, total_entries: 1 },
  filter:  { name: "", sectors: [], locations: [], status: null, changed: null },
  loading: false,
};

const getters = {
  getStockTakings: state => [...state.stockTakings],
  getInventoryChanges: state => state.inventoryChanges,
  getSingleStockTaking: state => id => state.stockTakings.find( s => s.id == id),
  getActiveStockTakings: state => state.stockTakings.filter( s => s.status !== "cancelled"),

  getInventoryChangesDetails: state => state.inventoryChangesDetails,
  getInventoryChangeDetailsByCode: state => code => state.inventoryChangesDetails.filter( s => s.variant.code == code),
  getPagination: state => state.pagination,
  isStockTakingLoading: state => state.loading,
  getStockTakingFilter: state => state.filter,
};

const actions = {
  fetchStockTakings({ commit, getters }) {
    commit('SET_LOADING_STATE', true)
    return new Promise((resolve) => {
          const { page, per_page } = getters['getPagination'];
          const { name, sectors, locations, status, changed } = getters['getStockTakingFilter'];
          post(`/inventory_check/list/`, { name, page, per_page, sectors, locations, status, changed }).then((res) => {
              commit("SET_StockTakings", res.data.entries);
              const { page, per_page, total_pages, total_entries } = res.data;
              commit("SET_PAGINATION", { page, per_page, total_pages, total_entries});
              commit('SET_LOADING_STATE', false)
              resolve( res );
          });
    });
  },

  updateStockTaking({ commit }, { id, data }) {
    return new Promise(resolve => {
      post(`/inventory_check/edit/${id}/`, data).then((res) => {
          commit("SET_InventoryChanges", res.data);
          commit("UPDATE_STOCK_TAKING", res.data);
          resolve( res );
      })
    });
  },

  fetchInventoryChanges({ commit }, {id, allItems}) {
    return new Promise((resolve, reject) => {
          get(`/inventory_check/list/${id}/?list_all=${allItems}`).then((res) => {
              commit('CLEAR_InventoryDetail');
              commit("SET_InventoryChanges", res.data);
              commit("UPDATE_STOCK_TAKING", res.data);
              if(res.data.inventoryChanges) commit('SORT_InventoryChanges');
              resolve( res );
          }).catch(() => {
            reject();
          });
    });
  },

  fetchInventoryOverview({ commit }, id) {
    return new Promise((resolve, reject) => {
          commit('CLEAR_InventoryDetail');
          get(`/inventory_check/detail/${id}/`).then((res) => {
              commit("SET_InventoryChanges", res.data);
              commit("UPDATE_STOCK_TAKING", res.data);
              if(res.data.inventoryChanges) commit('SORT_InventoryChanges');
              resolve( res );
          }).catch(err => {
            console.log(err);
            reject();
          });
    });
  },

  newStockTaking({ commit, dispatch }, { sectors, name, locations }) {
      return new Promise((resolve, reject) => {
          let data = { name };
          if(sectors.length > 0){
             data = {...data, sectors};
          }
          else {
            data = {...data, locations};
          }

          post('/inventory_check/start/', data).then((res) => {
              commit("ADD_StockTaking", {id: res.data.inventory_check_id, status: null});
              dispatch("fetchStockTakings");
              resolve( res.data ); //Return data for info-box
          }).catch((e) => {
            reject(e);
          });
      });
    },

    cancelStockTaking({ commit }, id) {
        return new Promise((resolve) => {
            del(`/inventory_check/cancel/${id}/`).then((res) => {
                commit("REMOVE_StockTaking", id);
                resolve( res ); //Return status
            });
        });
      },

    /*  POPULATE ITEMS  */
    populateInvetoryChanges({ commit }) {
        return new Promise((resolve) => {
            if(!state.inventoryChanges.changes) resolve()
            if(state.inventoryChanges.changes.length == 0) resolve();
            else
                state.inventoryChanges.changes.forEach((item, i) => {
                    const detail = state.inventoryChanges.product_details[item.code];
                    if(detail) commit("ADD_InventoryDetail", {index: i, productDetails: detail});
                    if(i == state.inventoryChanges.changes.length-1) {
                      resolve();
                    }
                });
        });
    },

    pipCheckProducts({ dispatch, commit }, {data, clearScanning, diff=null} ) {
      return new Promise((resolve, reject) => {
          post(`/inventory_check/add_product/`, data).then((res) => {
              if(res.data.errors.length == 0) {
                if(clearScanning) dispatch('clearScanning');
                if(diff) commit('UPDATE_InventoryDetailByCode', {data: data, diff})
                resolve( res ); //Return status
              } else {
                reject( res.data.errors );
              }
          }).catch((e) => {
            reject(e);
          });
      });
    },

    unPipCheckProducts({ commit }, {data, diff} ) {
      return new Promise((resolve, reject) => {
          del(`/inventory_check/remove_product/`, data).then((res) => {
              if(res.data.errors.length == 0) {
                commit('UPDATE_InventoryDetailByCode', {data: data, diff});
                resolve( res ); //Return status
              } else {
                reject();
              }
          }).catch(() => {
            reject();
          });
      });
    },


    commitStockTaking({ commit, getters }, {stockTakingId, sync} ) {
      sync = getters.getEshopConfig.is_public_api ? false : sync;
      return new Promise((resolve, reject) => {
          post(`/inventory_check/commit/`, {id: stockTakingId, shoptet_sync: sync}).then(() => {
                commit('CLEAR_InventoryChanges');
                commit('CLEAR_InventoryDetail');
                resolve(); //Return status
          }).catch((e) => {
            reject(e);
          });
      });
    },

};

const mutations = {
  SET_StockTakings: (state, items) => state.stockTakings = items,
  UPDATE_STOCK_TAKING: (state, newData) => {
    const index = state.stockTakings.findIndex(s => s.id == newData.id);
    if(index == -1) state.stockTakings.push(newData)
    else {
      const newStockTakings = [...state.stockTakings];
      newStockTakings[index] = newData;
      state.stockTakings = newStockTakings;
    }
  },
  ADD_StockTaking: (state, newItem) => state.stockTakings.push(newItem),
  REMOVE_StockTaking: (state, id) => ( state.stockTakings = state.stockTakings.filter( s => s.inventory_check_id !== id) ),
  SET_InventoryChanges: (state, inventory) => state.inventoryChanges = inventory,
  CLEAR_InventoryChanges: (state) =>  state.inventoryChanges = [],
  CLEAR_InventoryDetail : (state) =>  state.inventoryChangesDetails = [],

  UPDATE_InventoryDetailByCode: (state, {data, diff}) => {
    const products = state.inventoryChangesDetails.filter( s => s.variant.code == data.products[0].code)
    let item = products.find(p => p.location_name == data.location_name);

    /* We update local data so we don't have to refresh all items */
    item.after_amount += diff;
    item.amount = item.after_amount;
  },
  SORT_InventoryChanges: (state) => { state.inventoryChanges.changes.sort((a, b) => {
    const DIFF_A =  a.after_amount - a.before_amount;
    const DIFF_B =  b.after_amount - b.before_amount;
    const NAME_A =  a.code;
    const NAME_B =  b.code;

    if(NAME_A < NAME_B) { return -1; }
    if(NAME_A > NAME_B) { return 1; }
    return Math.abs(parseInt(DIFF_B)) - parseInt(DIFF_A);
    })
  },
  ADD_InventoryDetail: (state, {index, productDetails}) => {
    state.inventoryChangesDetails.push(
      {
      ...state.inventoryChanges.changes[index],
      name: productDetails.name,
      variant: {
        ean: productDetails['variant.ean'],
        image: productDetails['variant.image'],
        name: productDetails['variant.name'],
        code: productDetails['variant.code'],
      },
      amount: state.inventoryChanges.changes[index].after_amount
    })
  },

  SET_PAGINATION: (state, pag) => state.pagination = pag,
  SET_LOADING_STATE: (state, status) => state.loading = status,

  SET_STOCKTAKING_FILTER: (state, filter) => state.filter = filter,
};

export default {
  state,
  getters,
  actions,
  mutations,
}
