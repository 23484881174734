import axios from "axios"
import Auth from '@/services/Auth'

const API_URL = process.env.VUE_APP_API;
const isDev = location.host.includes('localhost');

function createAxiosInstance(method) {

  const testingDB =  process.env.NODE_ENV === 'TESTING' || window.Cypress ? {'X-DATABASE' : 'cypress-testing'} : {};
  
  return axios.create({
    withCredentials: !isDev, //Send cookies if we are not on development
    method: method,
    baseURL: API_URL,
    validateStatus: () => {
      return true;
    },
    headers: {
      ...testingDB,
      ...authorizationHeader()
    }
  });
}

function checkResponse(res) {
  return new Promise((resolve, reject) => {
    if (res.status < 304) {
      resolve(res);
    } else if (res.status === 401 || res.status === 403) {
      reject({ status: 403, message: "Uživatel není přihlášen." })
      Auth.logout();
    } else {
      reject({ status: res.status, payload: res.data });
    }
  });
}

function handleError(e) {
  return Promise.reject(e);
}

const authorizationHeader = () => {
  if (isDev) return { 'Authorization': 'Bearer ' + Auth.devToken };
  return {}
};

async function createMethod(method, uri, data, headers, responseType = 'json', timeout = 0) {
  await Auth.checkTokenRefresh();
  const instance = createAxiosInstance(method);
  return instance.request(uri, { data, headers: { ...headers } , responseType, timeout});
}

export function get(uri, headers) {
  return createMethod('get', uri, {}, headers)
    .then(checkResponse)
    .catch(handleError);
}

export function post(uri, data, headers, responseType, timeout) {
  return createMethod('post', uri, data, headers, responseType, timeout)
    .then(checkResponse)
    .catch(handleError);
}

export function del(uri, data, headers) {
  return createMethod('delete', uri, data, headers)
    .then(checkResponse)
    .catch(handleError);
}

export function put(uri, data, headers) {
  return createMethod('put', uri, data, headers)
    .then(checkResponse)
    .catch(handleError);
}

export function patch(uri, data, headers) {
  return createMethod('patch', uri, data, headers)
    .then(checkResponse)
    .catch(handleError);
}
