import { render, staticRenderFns } from "./SideMenuTemplate.vue?vue&type=template&id=1289ee20&scoped=true&"
import script from "./SideMenuTemplate.vue?vue&type=script&lang=js&"
export * from "./SideMenuTemplate.vue?vue&type=script&lang=js&"
import style0 from "./SideMenuTemplate.vue?vue&type=style&index=0&id=1289ee20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1289ee20",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAppBarNavIcon,VCard,VChip,VDivider,VIcon,VImg,VLayout,VListGroup,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VMenu,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/home/worker/workspace/infra__skladovy_system-frontend_builder-FE/brani-warehouse/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1289ee20')) {
      api.createRecord('1289ee20', component.options)
    } else {
      api.reload('1289ee20', component.options)
    }
    module.hot.accept("./SideMenuTemplate.vue?vue&type=template&id=1289ee20&scoped=true&", function () {
      api.rerender('1289ee20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SideMenuTemplate.vue"
export default component.exports