import { post } from '@/services/api'

const state = {
  settings: {},
};

const getters= {
  getSettings: state => state.settings,
};

const actions= {
  updateSettings({ commit }, settings) {
      return new Promise((resolve) => {
              post('/update_common_settings/', settings ).then((res) => {
                commit("ADD_PRODUCT_DETAIL", res.data);
                resolve( res.data ); //Return product
              }).catch(() => {
                resolve( [] );
              });
      });
    },

    setLogLodaingStatus({commit}, status) {
      commit('SET_LOGS_LOADING', status);
    },
};

const mutations= {
  SET_SETTINGS: (state, settings) => { if(settings !== null) state.settings = settings },
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
