const state = {
  visible: false,
};

const getters= {
  isVisible: state => state.visible,
};

const actions= {

  setLoading({commit}) {
    commit('SET_VISIBLE', true);
  },

  closeLoading({commit}) {
    commit('SET_VISIBLE', false);
  },
};

const mutations= {
  SET_VISIBLE: (state, status) => state.visible = status,
};

export default {
  namespaced: true,
  name: 'scanLoadingSpinner',
  state,
  getters,
  actions,
  mutations,
}
