const state = {
    isLoggedIn: false,
    clientInfo: undefined,

    onlineStatus: true,
    isUserLoaded: false,
};

const getters= {
  isUserLoggedIn: state => state.isLoggedIn,
  getOnlineStatus: state => state.onlineStatus,
  getClientInfo: state => state.clientInfo,
  isUserLoaded: state => state.isUserLoaded,
};

const actions= {
  setOnlineStatus({commit}, status) {
    commit('SET_ONLINE_STATUS', status);
  },


};

const mutations= {
  SET_USER_STATUS: (state, status) => state.isLoggedIn = status,
  SET_ONLINE_STATUS: (state, status) => state.onlineStatus = status,
  SET_CLIENT: (state, client) => state.clientInfo = client,
  SET_USER_LOADED: (state, status) => state.isUserLoaded = status,
};

export default {
  namespaced: true,
  name: 'user',
  state,
  getters,
  actions,
  mutations,
}
