var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: 500 },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { "data-cy": "prompt-dialog" } },
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.getDialog.title) + " ")]),
          _c("v-card-text", {
            domProps: { innerHTML: _vm._s(_vm.getDialog.text) },
          }),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _vm._t("buttons", function () {
                return [
                  _c("v-spacer"),
                  !_vm.getDialog.disableCancel
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "red--text",
                          attrs: { text: "", "data-cy": "prompt-cancel" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" Zrušit ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        "data-cy": "prompt-confirm",
                      },
                      on: { click: _vm.confirm },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.getDialog.confirmText || "Ano") + " "
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }