<template>
  <v-dialog
    v-model="showDialog"
    :width="500">
    <v-card data-cy="prompt-dialog">
      <v-card-title> {{ getDialog.title }} </v-card-title>
      <v-card-text v-html="getDialog.text">
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="buttons">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!getDialog.disableCancel"
            class="red--text"
            text
            data-cy="prompt-cancel"
            @click="cancel">
            Zrušit
          </v-btn>
          <v-btn
            color="primary"
            text
            data-cy="prompt-confirm"
            @click="confirm">
            {{ getDialog.confirmText || 'Ano' }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  export default {
    template: 'ConfirmDialog',
    computed: {
      ...mapGetters('Dialog', ['getDialog', 'getCancel', 'getConfirm']),
      showDialog: {
        get() {
            return this.getDialog.text !== undefined;
          },
        set(){
            this.closeDialog();
          }
      },
    },
    methods: {
      ...mapActions('Dialog', ['closeDialog']),
      cancel() {
        if(typeof this.getCancel === 'function') this.getCancel();
        this.closeDialog();
      },
      confirm() {
        if(typeof this.getConfirm === 'function') this.getConfirm();
      },
    }
  }
</script>

<style lang="css" scoped>
</style>
