var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SideMenuTemplate", {
    attrs: { appInfo: _vm.createAppInfo, menuItems: _vm.menuItems },
    on: {
      logout: function ($event) {
        return _vm.logout()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _vm.getOrgStocks.length > 1
              ? _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-select", {
                          attrs: {
                            label: "Sklad",
                            value: _vm.getCurrentStock,
                            items: _vm.getOrgStocks,
                            dense: "",
                          },
                          on: { change: _vm.changeCurrentStock },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }