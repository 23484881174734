import store from '@/store/'
import { get } from '@/services/api'
import axios from 'axios'

const isDev = location.host.includes('localhost');

export default class Auth {

  static get authUrl() {
    const defaultUrl = 'https://auth.brani.cz'
    const authUrl = {
      be: defaultUrl,
      fe: defaultUrl 
    }
    if(  process.env.VUE_APP_AUTH_FE &&  process.env.VUE_APP_AUTH_BE ) {
      authUrl.be = process.env.VUE_APP_AUTH_BE;
      authUrl.fe = process.env.VUE_APP_AUTH_FE;
    }

    return authUrl;
  }

  static get devToken() {
    return localStorage.getItem('authToken')
  }

  static get userInfo() {
    return store.getters['User/getClientInfo']
  }

  static userParameter(param) {
    const loggedUser = store.getters['User/getClientInfo'];
    if(!loggedUser) return '';

    return loggedUser[param]
  }

  static async setDevToken({token, refresh, expire, hash}) {
    localStorage.setItem('authToken', token)
    localStorage.setItem('orgHash', hash)
    if(refresh) localStorage.setItem('refreshToken', refresh)
    localStorage.setItem('expireToken', expire)

    //Load the data
    return this.initAuth(); 
  }

  static initAuth() {
    return new Promise((resolve, reject) => {
      get(`${this.authUrl.be}/client_info/`).then(res => {
        store.commit('User/SET_CLIENT', res.data)
        store.commit('User/SET_USER_LOADED', true)
        resolve()
      }).catch(() => {
        reject()
      })
    })
    
  }

  static logout() {
    localStorage.removeItem('authToken');
    window.location.replace(`${this.authUrl.fe}/logout`)
  }

  static get parsedCookies() {
    if (!document.cookie) return null;

    const split = document.cookie.split("; ");
    const cookieObject = {};

    split.forEach(cookie => {
        const [key, value] = cookie.split('=');
        cookieObject[key] = value;
    });

    return cookieObject;
  }
  

  static refreshToken() {
    return new Promise((resolve) => {
      const refreshToken = localStorage.getItem('refreshToken');
      const authToken = localStorage.getItem('authToken');

      if(!refreshToken && isDev) this.logout();
      //We need to bypass the default API service since it would loop forever trying to refresh the token
      const params = isDev ? `?access_token=${authToken}&refresh_token=${refreshToken}` : '';

      axios.get(`${this.authUrl.be}/refresh-token/${params}`, { withCredentials: !isDev }).then(res => {
        if(!isDev) resolve();

        const token = res.data.access_token;
        const orgToken = res.data.token_hash;
        const expiryToken = res.data.access_token_expire;

        localStorage.setItem('authToken', token);
        localStorage.setItem('orgToken', orgToken);
        localStorage.setItem('expireToken', expiryToken);
        resolve();
      }).catch(() => {
        this.logout();
      });
    });
  }

  static checkTokenRefresh() {
    return new Promise((resolve) => {
      if(!isDev) {
        const cookies = this.parsedCookies;
        if(cookies === null) this.logout();

        const expire = new Date(cookies.access_token_expire);
        const date = new Date();

        //Token is still valid
        if(expire > date) {
          resolve();
        } else {
          this.refreshToken().then(() => {
            resolve()
          });
        }
      } else {
        
        const expireToken = localStorage.getItem('expireToken');
        const expire = new Date(expireToken);
        const date = new Date();

        if(expire > date) {
          resolve();
        } else {
          this.refreshToken().then(() => {
            resolve()
          });
        }

      }
    })
  }

}