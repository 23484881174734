/* EXAMPLE
  minimal: this.$snackbar.set({text:'Produkt se nepodařilo najít', type:'error'});

  text: String - text of the snackbar
  icon: Boolean - false - to disable icons, String to overwrite default one.
  timeout: Number - how long should the snackbar stay visible, if not set, it will display a close button
  type: Vuetifycolors - error, success, warning, info etc.
*/

const state = {
  snackParams: {},
};

const getters= {
  getSnack: state => state.snackParams,
};

const actions= {

  setSnack({commit}, params) {
    commit('SET_SNACK', params);
  },

  closeSnack({commit}) {
    commit('DEL_SNACK');
  },
};

const mutations= {
  SET_SNACK: (state, text) => state.snackParams = text,
  DEL_SNACK: (state) => state.snackParams = {},
};

///// TODO: make this namespaced


export default {
  namespaced: true,
  name: 'snackbar',
  state,
  getters,
  actions,
  mutations,
}
