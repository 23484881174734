import { get, post, patch } from '@/services/api'

const state = {
  shoppingLists: [],
  shoppingProducts: {},
  customStates: {},
  loading: false,
  comments: [],
  moveMissing: [],
};

const getters= {
  getShoppingLists: (state) => state.shoppingLists,
  getShoppingListById: state => id => state.shoppingLists.find(l => l.shoplist_id == id),
  getShoppingProducts: (state) => state.shoppingProducts,
  getShoppingListComments: (state) => state.comments,
  getCustomStates: (state) => state.customStates,
  getMoveMissing: (state) => state.moveMissing,
  isShoppingListLoading: (state) => state.loading,
};

const actions= {
  fetchShoppingLists({commit}) {
      commit('SET_LOADING_STATUS', true)
      get('/ao/shoplists/').then((res) => {
        commit('SET_LOADING_STATUS', false)
        commit('SET_SHOPPING_LIST', res.data);
      })
  },

  fetchCustomStates({commit}){
    return new Promise((resolve) => {
      get('/ao/custom_shoplist_states/').then((res) => {
        commit('SET_CUSTOM_SHOPLIST_STATES', res.data);
        resolve(res.data);
      })
    })
  },

  searchShoppingLists({commit}, query) {
      commit('SET_LOADING_STATUS', true)
      post('/ao/shoplist/search/', {q: query}).then((res) => {
        commit('SET_LOADING_STATUS', false)
        commit('SET_SHOPPING_LIST', res.data);
      })
  },

  fetchProductsWithinSLById({commit}, id) {
    return new Promise((resolve) => {
      get(`/ao/shoplist/${id}/`).then((res) => {
        commit('SET_SHOPPING_PRODUCTS', res.data);
        resolve();
      })
    })
  },

  submitShoppingList({commit, getters}, id) {
    return new Promise((resolve, reject) => {
      post(`/ao/shoplist/${id}/process/`, {
        move_missing: getters.getMoveMissing,
        create_extras: getters.getCreateExtras,
      }).then((res) => {
        if(res.data.status == 'ok') {
          resolve();
          commit('CLEAR_LISTS');
        }
      })
      .catch(() => {
        reject();
      })
    })
  },

  submitComment({commit}, {comment, id}) {
    return new Promise((resolve, reject) => {
      post(`/ao/shoplist/${id}/comment/`, {body: comment}).then((res) => {
        commit('SET_COMMENTS', res.data);
        resolve(res.data);
      }).catch(() => {
        reject();
      })
    })

  },

  fetchShoppingListComments({commit}, id) {
    return new Promise((resolve, reject) => {
      get(`/ao/shoplist/${id}/comment/`).then((res) => {
          resolve();
          commit('SET_COMMENTS', res.data);
      })
      .catch(() => {
        reject();
      })
    })
  },

  changeShopListState({commit}, {state, id}) {
    return new Promise((resolve, reject) => {
      patch(`/ao/shoplist/${id}/status/`, {status: state}).then((res) => {
        resolve();
        commit('SET_SHOPPING_LIST', res.data);
      })
      .catch(() => {
        reject();
      })
    })
  },

  archiveShoppingList({commit}, id) {
    return new Promise((resolve, reject) => {
      patch(`/ao/shoplist/${id}/archive/`).then((res) => {
        resolve();
        commit('SET_SHOPPING_LIST', res.data);
      })
      .catch(() => {
        reject();
      })
    })
  },


  updateShoppingProducts({commit}, data) {
    commit('SET_SHOPPING_PRODUCTS', data)
  },

  addProductsToMoveMissing({commit}, codes) {
    commit('ADD_MOVE_MISSING', codes);
  },

};

const mutations= {
  SET_SHOPPING_LIST: (state, lists) => state.shoppingLists = lists,
  SET_SHOPPING_PRODUCTS: (state, products) => {
    const productsWithFulfillmentStatus = products.map(product => {
      let totalFullfilled = product.amount_stocked_up;

      if(!product.orders) return { ...product, details: {}, orders: [] };

      const ordersWithFulfillmentStatus = product.orders.map(order => {
        let isFulfilled = false;
        if(totalFullfilled - order.count >= 0) {
          totalFullfilled -= order.count;
          isFulfilled = true;
        }
        return { ...order, isFulfilled}
      });
    
      return {
        details: {},
        ...product,
        orders: ordersWithFulfillmentStatus,
      };
    });

    products = productsWithFulfillmentStatus.map(p => {return { details: {}, ...p }})
    const excessProducts = products.filter(p => p.amount_stocked_up > p.amount_expected);
    const lackingProducts = products.filter(p => p.amount_stocked_up < p.amount_expected);
    const correctProducts = products.filter(p => p.amount_stocked_up == p.amount_expected);
    state.shoppingProducts = {excessProducts, lackingProducts, correctProducts};
  },
  SET_COMMENTS: (state, comments) => state.comments = comments.reverse(),

  CLEAR_LISTS: (state) => {
    state.shoppingLists = [];
    state.shoppingProducts = {};
    state.moveMissing = [];
    state.getCreateExtras = [];
  },

  ADD_MOVE_MISSING: (state, codes) => {
    state.moveMissing = codes;
  },

  SET_LOADING_STATUS: (state, status) => state.loading = status,
  SET_CUSTOM_SHOPLIST_STATES: (state, states) => state.customStates = states,

};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
