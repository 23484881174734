export default class Dialog {
    constructor(store) {
        this.store = store;
    }

    show(params) {
      this.store.dispatch('Dialog/setDialog', params);
    }

    close() {
      this.store.dispatch('Dialog/closeDialog');
    }

}

Dialog.install = (Vue, options) => {
    const dialog = new Dialog(options.store);
    Vue.$dialog = dialog;
    Vue.prototype.$dialog = dialog;
};
