<template>
  <div v-if="isVisible" class="spinner">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters('ScanLoadingSpinner', ['isVisible'])
    }
  }
</script>

<style lang="css" scoped>
  .spinner {
    text-align: center;
    z-index: 999999;
    top: 50vh;
    background-color: white;
    padding: 5px;
    border: thin solid rgba(0, 0, 0, 0.12);
    position: absolute;
  }

  @media screen and (max-width: 960px) {
    .spinner {
      left: 50vw;
    }
  }

  @media screen and (min-width: 960px) {
    .spinner {
      left: calc(50vw + 48px)
    }
  }
</style>