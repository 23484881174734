import { get, del } from '@/services/api'

const state = {
  products: [],
  loading: true,
};

const getters= {
  getProducts: state => state.products,
  getProductByCode: state => code => state.products.find(p => p.code == code),
  isLoading: state => state.loading,
};

const actions= {
    fetchProductGroups({commit}) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      get('/inventory/set_products/').then((res) => {
        commit('SET_PRODUCTS', res.data);
        commit('SET_LOADING', false);
        resolve();
      }).catch(() => {
        commit('SET_LOADING', false);
        reject();
      })
    });
  },

  deleteSingle({commit, getters}, code) {
    return new Promise((resolve, reject) => {
      const product = getters.getProductByCode(code);
      const products = [{code: product.code}];
      const { location_name, sector_name } = product;

      del('/inventory/set_products/', {products, location_name, sector_name, source: 'smazanana-sada', shoptet_sync: false }).then((res) => {
        commit('REMOVE_PRODUCTS', code);
        resolve(res);
      }).catch(() => {
        reject();
      })
    });
  },

};

const mutations= {
  SET_PRODUCTS: (state, products) => state.products = products,
  REMOVE_PRODUCTS: (state, code) => state.products = state.products.filter(p => p.code !== code),
  SET_LOADING: (state, bool) => state.loading = bool,
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
